<template>
  <div>
    <div class="font" style="z-index:10000000">
      <div
        class="d-flex flex-column text-center "
        style="background-color:#013366; 
        z-index:10000000"
      >
        <!-- <div class="d-flex flex-column mx-auto w-100">
          <img class="w-100" :src="websiteLogo" />
        </div> -->
        <div class="d-flex flex-column text-center">
          <h6
            class="name font-weight-bold mb-0"
            style="color: white; margin-top: 25px"
          >
            {{ passUserInfo.user_name }}
          </h6>
        </div>
        <div
          class="d-flex flex-column text-center"
          style="margin-top: 5px; margin-bottom: 25px"
        >
          <h6 class="name font-weight-bold mb-0 mx-auto">
            {{ currencyFormat(passUserInfo.balance) }} {{ getUserCurrency() }}
          </h6>
        </div>
      </div>
    </div>

    <div class="menu-container mb-5">

      <a href="/my-info" class="sb-item-row"
        ><span class="sb-icon"><img class="w-100" :src="icon8user" /></span>
        <span class="sb-text font" style="color: black; padding: 5px">{{
          $t("title.user_center")
        }}</span></a
      >

      <hr />
      <div class="row no-gutters px-5 sub-menu">
        <div class="col">{{ $t("title.report") }}</div>
      </div>

      <a href="/allstatement" class="sb-item-row">
        <span class="sb-icon">
          <img class="w-100" :src="icon8history" />
        </span>
        <span class="sb-text" style="color: black; padding: 5px">{{
          $t("title.all_report")
        }}</span>
      </a>

      <a href="/deposit" class="sb-item-row">
        <span class="sb-icon"><img class="w-100" :src="icon8debit" /> </span>
        <span class="sb-text" style="color: black; padding: 5px">{{
          $t("title.cash_ins")
        }}</span>
      </a>

      <a href="/withdraw" class="sb-item-row"
        ><span class="sb-icon"><img class="w-100" :src="icon8Wallet" /></span>
        <span class="sb-text" style="color: black; padding: 5px">{{
          $t("title.withdrawals")
        }}</span>
      </a>

      <hr />
      <div class="row no-gutters px-5 sub-menu">
        <div class="col">{{ $t("title.other") }}</div>
      </div>

      <a class="sb-item-row">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span class="sb-icon"
              ><img class="w-100" src="/images/icons8-language-96.png"
            /></span>
            <span
              class="sb-text"
              v-bind="attrs"
              v-on="on"
              style="color: black; padding: 5px"
            >
              {{ $t("title.languages") }}
            </span>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
              @click="handleChangeLanguage(item.title)"
            >
              <v-list-item-icon>
                <img :src="item.icon" style="width: 30px" />
              </v-list-item-icon>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </a>

      <!-- <hr />
      <div class="sub-menu">Account Settings</div>

      <a href="/my-info" class="sb-item-row"
        ><span class="sb-icon"><img class="w-100" :src="icon8user" /></span>
        <span class="sb-text" style="color: black; padding: 5px"
          >Edit Account</span
        ></a
      > -->
    </div>

    <div
      style="
        position: absolute;
        width: 60%;
        bottom: 0;
        margin: 0 auto;
        left: 22%;
      "
    >
      <v-btn
        x-large
        block
        color="#DCDCDC"
        @click="_onSubmitLogout"
        class="rounded-pill"
        style="text-transform: unset !important; bottom: 5vh"
      >
        <span class="sb-text" style="color: black; font-weight: bold">
          {{ $t("title.logout") }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import "@/assets/css/header.css";
export default {
  props: ["passUserInfo"],
  data: () => ({
    drawer: false,
    websiteLogo: "/images/logo.png",
    icon8user: "/images/icons8-user.png",
    icon8debit: "/images/icons8-debit-card.png",
    icon8Wallet: "/images/icons8-coin-wallet.png",
    icon8history: "/images/icons8-order-history.png",
    icon8logout: "/images/icons8-log-out.png",
    khmer: "/images/flag-kh.png",
    eng: "/images/flag-en.webp",
    thai: "/images/flag-th.jpg",
    lang: "/images/icons8-language-96.png",

    items: [
      { title: "Khmer", icon: "/images/flag-kh.png" },
      { title: "English", icon: "/images/flag-en.webp" },
      { title: "Thai", icon: "/images/flag-th.jpg" },
    ],
  }),
  methods: {
    _onSubmitLogout() {
      this.$emit("invokeLogout");
    },
    getUserCurrency() {
      if (this.passUserInfo.currency_type === 1) {
        return "$";
      } else if (this.passUserInfo.currency_type === 2) {
        return "៛";
      } else if (this.passUserInfo.currency_type === 3) {
        return "฿";
      }
    },
    handleChangeLanguage(language) {
      this.lang = language;
      // console.log(language);
      switch (language) {
        case "English":
          this.changeLangEn();
          break;
        case "Khmer":
          this.changeLangKh();
          break;
        case "Thai":
          this.changeLangTh();
          break;
      }
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    changeLangEn() {
      let lang = this.$cookies.get("local");

      this.$i18n.locale = lang && lang == "english" ? "english" : "english";
      this.$cookies.set("local", this.$i18n.locale);
    },
    changeLangKh() {
      let lang = this.$cookies.get("local");
      this.$i18n.locale = lang && lang == "khmer" ? "khmer" : "khmer";
      this.$cookies.set("local", this.$i18n.locale);
    },
    changeLangTh() {
      let lang = this.$cookies.get("local");
      this.$i18n.locale = lang && lang == "thai" ? "thai" : "thai";
      this.$cookies.set("local", this.$i18n.locale);
    },
    changeLangVN() {
      let lang = this.$cookies.get("local");
      this.$i18n.locale = lang && lang == "vietnam" ? "vietnam" : "vietnam";
      this.$cookies.set("local", this.$i18n.locale);
    },
  },
};
</script>
<style scoped>
  .font{
    font-family: 'SegoeUI', 'Koh Santepheap' !important;
  }
</style>