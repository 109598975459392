<template>
  
    <DesktopScreenContainer :passChannel="channel" :passChannelList="channelList"
        :passSelectedChannel="channelSelected" :passNewChannelList="newChannelList" :passCurrentBetting="currentBetting"
        :passResult="resultObj" :passChannelStatus="channelStatus" :passGetButton="getButton"
        :passBettingTypeMaster="bettingTypeMaster" :passWeight="weightObj" :passTodayReport="todayBetting"
        :passLoadingTodayReport="loadingTodayReport" :passUserCurrency="userCurrency"
        v-on:sentSubmitBetting="createBetting" v-on:sentRefreshTodayReport="callTodayReport"
        v-on:sentRefreshCurrentReport="fetchCurrentBetting" v-on:sentSetChannel="setChannelType">
      </DesktopScreenContainer>
  
</template>

<script>
// Style Css
import "@/assets/css/dashboard/dashboard_share.css";
import "@/assets/css/dashboard/max_480.css";
import "@/assets/css/dashboard/min_481.css";
import "@/assets/css/dashboard/min_768.css";
import "@/assets/css/dashboard/min_993.css";
import "@/assets/css/dashboard/min_1025.css";
import "@/assets/css/dashboard/min_1281.css";
import "@/assets/css/dashboard/min_1441.css";
import "@/assets/css/dashboard/min_1661.css";
import "@/assets/css/dashboard/min_1824.css";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import DesktopScreenContainer from "./components/DesktopScreenComponent/DesktopScreenContainer.vue";
// import MobileScreenContainer from "./components/MobileScreenComponent/MobileScreenContainer.vue";

export default {
  components: {
    DesktopScreenContainer,
    // MobileScreenContainer
  },
  data() {
    return {
      active_tab: 0,
      channelList: [],
      newChannelList: [],
      channelSelected: 1,
      tabs: null,
      bettingMoney: 0,
      loading: false,
      error: false,
      bettingObj: {
        number_id: null,
        amount: null
      },
      currencyType: "",
      betSelected: null,
      isBetsuccess: true,
      numberRule: val => {
        if (val < 0) return 'Please enter a positive number'
        return true
      },
      isBetting: false,
      userCurrency: ''
    }
  },

  async mounted() {

  },
  watch: {
    channelId: async function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.channelList = this.$cookies.get("sbc_user").home_channel;
        this.RELOAD_PAGE()
        this.SWITCH_CHANNEL_LOADING(true);
        this.$socket.disconnect()
        this.connectedSocket();
        await this.fetchChannel();
        // console.log(`channelId: function (newVal, oldVal) {`);
        this.fetchResult();
        if (newVal == 1 || newVal == 2 || newVal == 3 || newVal == 4|| newVal == 15 || newVal == 16 ) this.fetchWeight();
        this.fetchButton();
        this.fetchBettingTypeMaster();
        this.fetchCurrentBetting();
        this.bettingMoney = 0;
        this.calculateData();
        this.SWITCH_CHANNEL_LOADING(false);
      }
    }
  },
  computed: {
    ...mapGetters("$_dashboard", {
      channel: "getChannel",
      channelStatus: "getChannelStatus",
      resultObj: "getResult",
      bettingTypeMaster: "getBettingTypeMaster",
      todayBetting: "getTodayBetting",
      currentBetting: "getCurrentBetting",
      weightObj: "getWeight",
      getButton: "getButton",
      loadingTodayReport: "getLoadingTodayReport",
    }),
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
      isSound: "getIsSound",
    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
    resultColor() {
      return this.showResultByColor(this.resultObj)
    },
    getStatus() {
      return this.checkSattus()
    },
    getStatusColor() {
      return this.checkSattusColor()
    },
    getResultStatus() {
      return this.resultStatue()
    },
    getChannelsColor(index) {
      return this.resultChannelColor(index)
    },
  },
  async created() {
    this.channelList = this.$cookies.get("sbc_user").home_channel;
    this.channelSelected = this.$cookies.get('sbc_home_channel') ? this.$cookies.get('sbc_home_channel') : this.$cookies.get("sbc_user").home_channel[0]
    this.UPDATE_CHANNEL_ID(this.channelSelected);
    this.connectedSocket();
    await this.fetchChannel();
    await this.fetchResult();
    await this.fetchButton();
    if (this.channelSelected == 15 || this.channelSelected == 16 || this.channelSelected == 1 || this.channelSelected == 2 || this.channelSelected == 3 || this.channelSelected == 4) this.fetchWeight();
    await this.fetchBettingTypeMaster();
    await this.fetchCurrentBetting();
    await this.callTodayReport();
    this.calculateData();
    this.getUserCurrency();
  },
  methods: {
    connectedSocket() {
      this.$connectSocket();
      this.listenerSocket()
    },
    listenerSocket() {
      this.$socket.on("client-update-channel-info", (data) => {
        console.log(`socket on client-update-channel-info: ${JSON.stringify(data)}`);
        if (data) {
          if (data.is_open) {
            this.playSound('place_your_bet_please_3.m4a')
          }
          this.updateChannel(data);
        }
      });
      this.$socket.on("client-status-channel-type", (data) => {
        // console.log(`socket on client-status-channel-type: ${JSON.stringify(data)}`);
        if (data) {
          // console.log(`socket on client-status-channel-type: ${JSON.stringify(data)}`);
          this.UPDATE_CHANNEL_STATUS(data);
        }
      });
      this.$socket.on("client-defind-result-and-new-channel", (data) => {
        // console.log(`socket on client-defind-result-and-new-channel: ${JSON.stringify(data)}`);
        this.callTodayReport();
        if (data) {
          this.updateResult(data);
          this.RESET_CARD();
        }
      });
      this.$socket.on("client-update-user-info", (data) => {
        // console.log(`socket on client-update-user-info: ${JSON.stringify(data)}`);
        if (data) {
          if (data.is_suspend) {
            this.$toastr.e(`Your account have been blooked!`)
            this.onLogout()
          }
          this.updateBalance(data.balance);
        }
      });
      this.$socket.on("client-update-betting-amount", (data) => {
        // console.log(`socket on client-update-betting-amount: ${JSON.stringify(data)}`);
        if (data) {
          if (this.channel.is_open) {
            this.fetchCurrentBetting();
            let currentcy = this.userInfor.currency_type - 1;
            this.UPDATE_AMOUNT(data[currentcy]);
          }
        }
      });
      this.$socket.on("client-update-payout", (data) => {
        // console.log(`socket on client-update-payout: ${JSON.stringify(data)}`);
        // console.log(`socket on client-update-payout channel: ${this.channel.is_open}`);
        if (data) {
          // console.log(`socket on client-update-payout type: ${this.userInfor.currency_type} :type:${data.currency_type}`);
          if (this.channel.is_open || this.channel.is_open == null) {
            if (this.userInfor.currency_type == data.currency_type) {
              this.UPDATE_PAYOUT(data);
            }
          }
        }
      });
      this.$socket.on("client-reserves-result", (data) => {
        // console.log(`socket on client-reserves-result: ${JSON.stringify(data)}`);
        if (data) {
          this.UPDATE_TODAY_BETTING(data);
        }
      });
      this.$socket.on("client-reset-amount", (data) => {
        // console.log(`socket on client-reset-amount: ${JSON.stringify(data)}`);
        if (data) {
          this.RESET_AMOUNT();
          if (this.userInfor.currency_type == 1) {
            this.UPDATE_AMOUNT_MAX(data.amount_max_usd)
          } else if (this.userInfor.currency_type == 2) {
            this.UPDATE_AMOUNT_MAX(data.amount_max_kh)
          } else if (this.userInfor.currency_type == 3) {
            this.UPDATE_AMOUNT_MAX(data.amount_max_tb)
          }
        }
      });
      if (this.$cookies.get('sbc_channel_type') != 15 ||this.$cookies.get('sbc_channel_type') != 16 || this.$cookies.get('sbc_channel_type') != 1 || this.$cookies.get('sbc_channel_type') != 2 || this.$cookies.get('sbc_channel_type') != 3 || this.$cookies.get('sbc_channel_type') != 4) {
        this.$socket.on("client-start-count-down", (data) => {
          if (data) {
            this.UPDATE_COUNTDOWN(data);
            if (data.countDown == 6) {
              this.playSound('no_more_bet_en.m4a');
            }
            if (data.countDown <= 10 && data.countDown >= 5) {
              this.playSound('ting1s.mp3');
              this.RESET_CARD();
            }
          }
        });
      }
      this.$socket.on("client-scan-player", data => {
        if (data) {
          this.playSound('bet-touch.mp3');
          if (this.$cookies.get('sbc_channel_type') == 6
            || this.$cookies.get('sbc_channel_type') == 7) this.UPDATE_PLAYER_CARD(data);
          if (this.$cookies.get('sbc_channel_type') == 8
            || this.$cookies.get('sbc_channel_type') == 9) this.UPDATE_DRAGON_CARD(data.image);
        }
      });
      this.$socket.on("client-scan-banker", data => {
        if (data) {
          this.playSound('bet-touch.mp3');
          if (this.$cookies.get('sbc_channel_type') == 6
            || this.$cookies.get('sbc_channel_type') == 7) this.UPDATE_BANKER_CARD(data);
          if (this.$cookies.get('sbc_channel_type') == 8
            || this.$cookies.get('sbc_channel_type') == 9) this.UPDATE_TIGER_CARD(data.image);
        }
      });
      this.$socket.on("client-scan-result", (data) => {
            if (data) {
              if (this.$cookies.get('sbc_channel_type') == 5 ) this.UPDATE_KLAKLOUK_CARD(data);
              if (this.$cookies.get('sbc_channel_type') == 10 ) this.UPDATE_LOTTO_CARD(data);
              if (this.$cookies.get('sbc_channel_type') == 11 ) {
                this.UPDATE_YUKI_CARD(data);}
              if (this.$cookies.get('sbc_channel_type') == 12 || this.$cookies.get('sbc_channel_type') == 17 ) this.UPDATE_ABONG_CARD(data);
              if (this.$cookies.get('sbc_channel_type') == 13 ) this.UPDATE_FANTAN(data);
              if (this.$cookies.get('sbc_channel_type') == 14 ) this.UPDATE_TAISIV(data);
            }
        });
    },
    playSound(soundName) {
      let chType = this.$cookies.get('sbc_channel_type');
      if (chType > 4){     
        if(this.isSound){
          let audio = new Audio(`/sound/${soundName}`);
          let promise = audio.play();
          if (promise) {
            promise.catch(function () {
            });
          }
        }
      }
    },
    onLogout() {
      this.$socket.removeAllListeners()
      this.$socket.disconnect()
      this.$cookies.remove('sbc_channel_type')
      this.$cookies.remove('sbc_token')
      this.$router.push("/login")
    },
    validateBeforeBetting(submitBettingData) {
      const money = submitBettingData.amount;
      const userBalance = this.userInfor.balance;
      if (this.getBettingStatus() && submitBettingData.amount > 0 && userBalance >= money && this.isBetting == false) {
        return true
      }
      else if (!this.getBettingStatus()) {
        this.bettingStatus = "Game is not open"
        this.isBetsuccess = false
        this.$toastr.e(`Game is not open`)
        return false
      }
      else if (submitBettingData.amount <= 0) {
        this.bettingStatus = "Please enter amount!"
        this.isBetsuccess = false
        this.$toastr.e(`Please enter amount!`)
        return false
      }
      else if (userBalance < money) {
        this.bettingStatus = "Not enought Balance"
        this.isBetsuccess = false
        this.$toastr.e(`Not enought Balance`)
        return false
      }
      else if (this.isBetting == true) {
        this.bettingStatus = "We processing your previous betting, please wait"
        this.isBetsuccess = false
        this.$toastr.e(`Please wait`)
        return false
      }
      else {
        this.$toastr.e(`something when wrong`)
        return false
      }
    },
    async createBetting(submitBettingData) {
      this.bettingObj = submitBettingData;
      if (this.validateBeforeBetting(submitBettingData)) {
        try {
          this.isBetting = true;
          this.LOADING(true);
          const response = await this.fetchCreateBetting(this.bettingObj);
          if (response.data.code) {
            this.$toastr.s(`Betting is success`)
            this.bettingStatus = "Betting is success"
            await this.updateBalance(response.data.data.balance)
            this.BETTING_DIALOG(false);
            this.LOADING(false);
            this.isBetting = false;
            this.UPDATE_BET_AMOUNT(response.data.data);
            this.UPDATE_CURRENT_BETTING(response.data.data)
            this.UPDATE_PAYOUT(response.data.data)
          } else {
            if (response.data.message.descriptions == "Channel is not open") {
              this.UPDATE_CH_STATUS(false);
            }
            this.isBetting = false;
            this.LOADING(false);
            this.$toastr.e(`${response.data.message.descriptions}`)
            this.bettingStatus = response.data.message.descriptions
          }
        } catch (error) {
          this.bettingStatus = "Betting is not success"
          this.isBetsuccess = false;
          this.LOADING(false);
          this.error = true;
          return error;
        } finally {
          this.bettingMoney = 0;
          this.isBetting = false;
          this.loading = false;
          this.LOADING(false);
        }
      }



    },

    getUserCurrency() {
      if (this.userInfor.currency_type === 1) {
        this.userCurrency = '$'
      } else if (this.userInfor.currency_type === 2) {
        this.userCurrency = '៛'
      } else if (this.userInfor.currency_type === 3) {
        this.userCurrency = '฿'
      }
    },

    async callTodayReport() {
      await this.fetchTodayBetting();
    },
    async setChannelType(channelType) {
      if ((this.channelSelected) != channelType) {
        this.LOADING(true);
        this.channelSelected = channelType;
        this.$cookies.set("sbc_home_channel", channelType);
        this.$cookies.set("sbc_channel_type", channelType);
        this.UPDATE_CHANNEL_ID(channelType);
        // this.calculateData(channelType)
        this.UPDATE_IS_REPORT(true)
      }
    },

    playAudioTing() {
      let audio = new Audio("/sound/ting1s.mp3");
      let promise = audio.play();
      if (promise) {
        //Older browsers may not return a promise, according to the MDN website
        promise.catch(function () { });
      }
    },
    showResultByColor(result) {
      if (result.results[0]) {
        if (result.results[0].name === "1" || result.results[0].name === "4") {
          return "red"
        } else {
          return "black"
        }
      } else {
        return "primary"
      }
    },
    getBettingStatus() {
      if (this.channel.is_open && this.userInfor.enable && this.channel.enable) {
        return true
      }
      return false
    },
    checkSattus() {
      if (this.channel.is_open == null) {
        return "Not Open"
      } else {
        if (this.channel.is_open) {
          return "Open"
        }
        return "Close"
      }
    },
    resultStatue() {
      if (this.channel.is_open == null && this.resultObj.results[0]) {
        return true
      } else {
        return false
      }
    },
    resultChannelColor(index) {
      if (this.channelStatus) {
        for (var i = 0; i < this.channelStatus.length; i++) {
          if (this.channelStatus[i].channel_type == index) {
            if (this.channelStatus[i].status == null) {
              return "orange"
            } else {
              if (this.channelStatus[i].status) {
                return "green"
              }
              return "red"
            }
          }
        }
      }
    },
    currencyFormat(num) {
      return parseInt(num).toLocaleString("en")
    },
    payoutFormat(num) {
      return num
    },
    checkSattusColor() {
      if (this.channel.is_open == null) {
        return "text-warning"
      } else {
        if (this.channel.is_open) {
          return "text-success"
        }
        return "text-danger"
      }
    },
    async calculateData() {
      // await this.fetchChannel();
      // console.log(`calculateData`);
      this.RESET_COUNTDOWN();
      if (this.channelSelected == 6 || this.channelSelected == 7) {
        let getData = this.channel.card_list;
        this.UPDATE_FIST_LOAD_BACARAT_CARD(getData);
      }
      if (this.channelSelected == 8 || this.channelSelected == 9) {
        let getData = this.channel.card_list;
        this.UPDATE_FIST_LOAD_TIGER_DRAGON_CARD(getData)
      }
    },

    ...mapMutations("$_dashboard", [
      "LATEST_RESULT",
      "UPDATE_CURRENT_BETTING",
      'UPDATE_CHANNEL',
      'UPDATE_RESULT',
      "UPDATE_AMOUNT",
      "UPDATE_PAYOUT",
      "UPDATE_TODAY_BETTING",
      "RESET_AMOUNT",
      "UPDATE_CHANNEL_STATUS",
      "RELOAD_PAGE",
      "CLEAR_CACH",
      "UPDATE_AMOUNT_MAX",
      "UPDATE_BET_AMOUNT",
      "UPDATE_CH_STATUS",
      "BETTING_DIALOG",
      "UPDATE_DRAGON_CARD",
      "UPDATE_TIGER_CARD",
      "UPDATE_FIST_LOAD_BACARAT_CARD",
      "RESET_CARD",
      "UPDATE_FIST_LOAD_TIGER_DRAGON_CARD",
      "UPDATE_IS_REPORT",
      "UPDATE_COUNTDOWN",
      "UPDATE_PLAYER_CARD",
      "UPDATE_BANKER_CARD",
      "RESET_COUNTDOWN",
      "UPDATE_KLAKLOUK_CARD",
      "UPDATE_LOTTO_CARD",
      "UPDATE_YUKI_CARD",
      "UPDATE_ABONG_CARD",
      "UPDATE_TAISIV",
      "UPDATE_FANTAN",
      "LOADING",
      "SWITCH_CHANNEL_LOADING"
    ]),
    ...mapActions("$_modules", ["updateBalance", "updateUserInfo", "fetchUserInfo"]),
    ...mapActions("$_dashboard", [
      "fetchResult",
      "fetchChannel",
      "fetchBettingTypeMaster",
      "fetchTodayBetting",
      "fetchCurrentBetting",
      "fetchCreateBetting",
      "updateCurrentBetting",
      "updateChannel",
      "updateResult",
      "fetchWeight",
      "fetchButton"
    ]),
    ...mapMutations("$_modules", [
      "UPDATE_CHANNEL_ID",
      "UPDATE_USER_INFO"
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.$socket.removeAllListeners();
    this.$socket.disconnect();
    this.CLEAR_CACH();
    next();
  }
};

</script>