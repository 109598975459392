<template>
    <v-container class="bg-surface-variant px-0" >
        <v-row no-gutters class="lotto-border">
          <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" xxl="3" class="lotto-border my-order-left" style="overflow: auto; height: auto;">
          <LeftSection/>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5" xxl="5" class="lotto-border my-order-middle" style="overflow: auto; height: auto;">
          <MiddleSection :passLastTwentyResults="lastTwentyResults" :passClientRandom="clientRandom"/>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" xxl="4" class="lotto-border my-order-right" style="background-color: #003466; overflow: auto; height: auto;" >
          <RightSection :passRandomNumber="randomNumber"  :passClientRandom="clientRandom" :passClientTime="clientTime" :passLastTwentyResults="lastTwentyResults"/>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
import LeftSection from "./components/leftSection.vue";
import MiddleSection from "./components/MiddleSection.vue";
import RightSection from "./components/RightSection.vue";
import {  mapState, mapGetters, mapMutations, mapActions  } from "vuex";
export default {
  components: {
    LeftSection,
    MiddleSection,
    RightSection
  },
  data() {
    return {
      message : "Hello world",
      orderResult : {},
      orderResultUnderOver:{},
      clientRandom: {
        lotto:[]
      },
      clientTime: {
        is_countdown: null,
        is_open_betting: null,
        time: null,

      },
      randomNumber :0
    }
  },
  watch: {
    channelId: async function (newVal, oldVal) {
      // console.log(newVal, oldVal)
      if (newVal != oldVal) {
        this.channelList = this.$cookies.get("sbc_user").channel_list;
        // this.RELOAD_PAGE()
        this.$socket.removeAllListeners()
        this.$socket.disconnect()
        this.connectedSocket();
        // await this.fetchChannel();
        // // console.log(`channelId: function (newVal, oldVal) {`);
        // this.fetchResult();
        // if (newVal == 1 || newVal == 2 || newVal == 3 || newVal == 4) this.fetchWeight();
        // this.fetchButton();
        // this.fetchBettingTypeMaster();
        // this.fetchCurrentBetting();
        // this.bettingMoney = 0;
        // this.calculateData();
      }
    }
  },
  computed: {
    ...mapGetters("$_modules", {
      channelId:"channelId",
      isSound: "getIsSound"
    }),
    ...mapGetters("$_machine", {
      lastTwentyResults: "getLastTwentyResult",

    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },
  created() {
    this.connectedSocket();
    this._initialData();
  },
  methods:{
    connectedSocket() {
      this.$connectSocket();
      this.listenerSocket()
    },
    _initialData() {
      this.fetchUserInfo();
      this.fetchLastResults();
      this.fetchCurrentBetting();
      this.fetchCurrentGame();
      this.fetchPreviousBetting();
      this.fetchButton();
    },
    listenerSocket(){
       // socket get random number, total number,
    this.$socket.on("client-random", (data) => {
      if (data && data.total != null) {
        let interval;
        const _this = this;
        interval = setInterval(function () {
          let result = parseInt(Math.random() * (80 - 1) + 1);
          _this.randomNumber = result;
        }, 50);
        setTimeout(() => {
          clearInterval(interval);
          _this.randomNumber = null;
          _this.clientRandom = data;
          _this._playSoundBang();
          this.$confetti.stop();
        }, 800);
      }
    });
    // socket get time,  is betting, is coldown
    this.$socket.on("client-time", (data) => {
      if (data) {
        // define client time to display count down time at play zone
        this.clientTime = data;
        // console.log(this.clientTime)
        // play audio sound
        if (data.time == 59) {
          this._playSoundBetDown();
        }
        if (data.time < 11 && data.time !== 0) this._playSoundTing();
        if (data.time == 0) this._playSoundGoodLuck();
      }
    });
    // get new game
    this.$socket.on("client-new-game", (data) => {
      if (data) {
        this.clientRandom = [];
        this.bonus = [];
        // this._clearResult();
        this._initialData();
      }
    });
    // get game result and bonus
    this.$socket.on("client-result", (data) => {
      if (data) {
        this.clientResult = data;
      }
    });
    this.$socket.on("client-bonus", (data) => {
      if (data) {
        this.bonus = data;
        if (this.bonus.is_bonus) {
          this.$confetti.start();
          this._playSoundCongratulation();
        }
      }
    });
    },
    _playSoundBang() {
      if(this.isSound){
        let audio = new Audio("/sound/bangbang.mp3");
        let promise = audio.play();
        if (promise) {
          promise.catch(function () {});
        }
      }
    },
    _playSoundBetDown() {
      if(this.isSound){
        let audio = new Audio("/sound/betdown_please.mp3");
        let promise = audio.play();
        if (promise) {
          promise.catch(function () {});
        }
      }
    },
    _playSoundTing() {
      if(this.isSound){
        let audio = new Audio("/sound/ting1s.mp3");
        let promise = audio.play();
        if (promise) {
          promise.catch(function () {});
        }
      }
    },
    _playSoundGoodLuck() {
      if(this.isSound){
        let audio = new Audio("/sound/good_luck.mp3");
        let promise = audio.play();
        if (promise) {
          promise.catch(function () {});
        }
      }
    },
    _playSoundCongratulation() {
      if(this.isSound){
        let audio = new Audio("/sound/congratulations.mp3");
        let promise = audio.play();
        if (promise) {
          promise.catch(function () {});
        }
      }
    },
    ...mapMutations("$_machine", [
      "LATEST_FIVE_RESULTS",
      "CURRENT_BETTING",
      "PREVIOUS_BETTING",
      "COUNT_TIME",
    ]),
    ...mapActions("$_machine", [
      "fetchLastResults",
      "fetchCurrentBetting",
      "fetchPreviousBetting",
      "fetchCurrentGame",
      "SocketInAction",
      "fetchButton",
    ]),
    ...mapActions("$_modules", [ "fetchUserInfo"]),
  },  
  name: 'IndexLotto',
  async beforeRouteLeave(to, from, next) {
    this.$socket.removeAllListeners()
    this.$socket.disconnect()
    next();
  }
}
</script>

<style>
.bg-surface-variant{  padding: 0; margin-top: 1px; max-width: 1360px!important;}
.lotto-nav-head{  background-color: #0c478b;   height: 50px;  overflow: hidden ;}
.lotto-nav-head .span-title{ font-size: 1.25rem;  display: block;  color: white;  padding: 14px 14px 0px 12px;}
.lotto-nav-head .span-btn{ width:15%; text-align: center;  font-size: 1.25rem;  display: inline-block;  color: white;  font-weight: 900;  padding: 14px 14px 12px 12px;}
.lotto-nav-head .span-active{  background-color: #14214b;}

.lotto-nav-head .head-right-right{   float: right;  padding-top: 6px;}
.lotto-nav-head .head-right-right ul{  list-style: none;  padding: 0;}
.lotto-nav-head .head-right-right ul li{   color: white; font-size: .75rem;  padding-right: 10px;}
.lotto-nav-head .head-right-right ul li .item-right{   float: right; margin-left: 10px;}

.lotto-nav-head .head-right-center{  padding-top: 6px;}
.lotto-nav-head .head-right-center ul{ list-style: none;  padding: 0;}
.lotto-nav-head .head-right-center ul li{  color: white; font-size: .75rem; padding-left: 10px;}
.lotto-nav-head .head-right-center ul li .item-right{  float: right; padding-right: 10px;}
.lotto-nav-head .head-right-last{ background-color: rgb(204, 0, 13, .7);  width: 100%;  min-width: 100%;  height: 100%;  min-height: 100%;  margin-top: 1px; position: relative; text-align: center; }
.lotto-nav-head .head-right-last .box-number{  
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #1edcfe;
  text-align: center;
  top: -1px;
  left: 1px;
  color: black;
  font-size: .75rem;
  line-height: 20px;
  font-weight: 600;
}
.lotto-nav-head .head-right-last .span-number{ font-size: 1.75rem; color: white;  display: block; line-height: 50px;}
.lotto-nav-head .head-right-last .span-number-on-run{ 
  color:  #1debc1!important; font-size:2.5rem; font-weight:800; text-shadow: 1px 3px #124a8d; margin-top:-3px}
.head-right-last-on-run
/*left content*/
.list-result-head ul{  list-style: none;  padding: 0 !important;}
.list-result-head ul li{padding-left: 6px !important; padding-top: 3px;padding-bottom: 3px;font-size: 1rem; color: #0c478b;}
.lotto-border{border: 2px solid #0c478b;border-top: 0; background-color: white;}
.title-current{background-color: #0c478b;color: white;padding: 8px 8px 12px 8px;font-size: 1.2rem;}
.list-result-body ul{list-style: none;padding: 0;padding-left: 8px !important;}
.list-result-body ul li{padding-top: 7px;
  padding-bottom: 3px;
  font-size: .75rem;
  color: #111416;
  height: 22px;}
.list-head li{color: #0c478b !important;font-size: .95rem !important;padding-top: 3px;padding-bottom: 3px;}
.list-head li .item-right{float: right;padding-right: 12px;}
.list-result-body ul li .row-item{ float: right;}
.list-result-body ul li .row-item .col-item-3:first-child{padding-left: 10px; padding-right: 40px;}
.list-result-body ul li .row-item .col-item-3{padding-left: 20px;}
.list-result-body ul li .row-item .col-item-3:last-child{padding-right: 20px;}
/*center content*/
.grid-container {display: grid;grid-template-columns: auto auto auto auto auto auto auto auto auto auto;gap: 0px;padding: 0; overflow: hidden;}
.grid-container > div {background: linear-gradient(180deg, #e0e1e5 0, #f1f1f1 51%, #e0e1e5);text-align: center;font-size: 1rem;padding-top: 5px;padding-bottom: 5px;border: 1px solid #111416; color: #111416;}
.grid-container .lotto-number {grid-column: 1 / 0.1;}
.lotto-result-between-min{padding-left: 6px;padding-right: 6px; padding-top: 6px; width: 100%; overflow-y:auto; }
.lotto-result-between-box{ border: 1px solid white;}
.lotto-result-between-title{height: 40px;background: linear-gradient(180deg, #dcdcdc 0, #ececec 51%, #d9ccc3);text-align: center;line-height: 40px; color: #111416;}

.lotto-result-between-min .tb-record{ border-collapse: collapse; width: 100%; height: 100%; }
.lotto-result-between-min .tb-record tr td{border: 1px solid #cacbcc; width: 20px; height: 20px; padding: 0;background-color: #ebdecd;}
.lotto-result-between-min .tb-record tr td .range_1{ line-height: 16px; margin-left: 3px; width: 16px; height: 16px; font-size: .75rem; font-weight:900; border-radius: 50%; background: radial-gradient(circle at 30% 120%, #323232, #efc60d 90%, #000 100%) !important;  color: white; text-align: center;}
.lotto-result-between-min .tb-record tr td .range_2{ line-height: 16px; margin-left: 3px; width: 16px; height: 16px; font-size: .75rem; font-weight:900; border-radius: 50%; background: radial-gradient(circle at 30% 120%, #323232, #110de6 90%, #000 100%) !important;  color: white; text-align: center;}
.lotto-result-between-min .tb-record tr td .range_3{ line-height: 16px; margin-left: 3px; width: 16px; height: 16px; font-size: .75rem; font-weight:900; border-radius: 50%; background: radial-gradient(circle at 30% 120%, #323232, #090 90%, #000 100%) !important; color: white; text-align: center;}
.lotto-result-between-min .tb-record tr td .range_4{ line-height: 16px; margin-left: 3px; width: 16px; height: 16px; font-size: .75rem; font-weight:900; border-radius: 50%; background: radial-gradient(circle at 30% 120%, #323232, #79071b 90%, #000 100%) !important; color: white; text-align: center;}
.lotto-result-between-min .tb-record tr td .range_5{ line-height: 16px; margin-left: 3px; width: 16px; height: 16px; font-size: .75rem; font-weight:900; border-radius: 50%; background: radial-gradient(circle at 30% 120%, #323232, #ff6882 90%, #000 100%) !important; color: white; text-align: center;}

.content-right-on-mobi{display: none; padding: 6px 6px 6px 6px !important;}

.x-x-lotto-resul-x-1{padding-bottom: 10px !important;}
.x-x-lotto-resul-x-2{padding-bottom: 10px !important;}
/*
.lotto-result-between-item-box{display: grid;grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto; gap: 0px;padding: 0; }
.lotto-result-between-item-box > .lotto-result-between-item {background-color: #ebdecd;text-align: center;font-size: 1rem;padding-top: 6px;padding-bottom: 15px;border: 1px solid #cacbcc;border-bottom: 0px;border-right: 0px;box-sizing: content-box;grid-column: 1 / 0.1;position: relative;}
.lotto-result-between-item-box .lotto-result-between-item span{position: absolute; font-size: .75rem;top: 5%;left: 5%;width: 15px;height: 15px;border-radius: 50%; text-align: center;}

.c-b-maroon{ color: white; background-color: #641e26;}
.c-b-palevioletred{color: white; background-color: #c54c5d;}
.c-b-gold{color: white; background-color: #aba000;}
.c-b-green{color: white; background-color: #1d6815;}
.c-b-blue{color: white; background-color: #131ac0;}
*/

/*
.lotto-result-between-item-box-smail-big{ display: grid;grid-template-columns: auto auto auto auto auto auto auto auto auto; gap: 0px;padding: 0;}
.lotto-result-between-item-box-smail-big > .lotto-result-between-item{background-color: #ebdecd;text-align: center;font-size: 1rem;padding-top: 6px;padding-bottom: 15px;border: 1px solid #cacbcc;border-bottom: 0px;border-right: 0px;box-sizing: content-box;grid-column: 1 / 0.1;position: relative;}
.lotto-result-between-item-box-smail-big .lotto-result-between-item span{ position: absolute; font-size: .75rem;top: 10%;left: 15%;width: 16px;height: 16px;border-radius: 50%; text-align: center;}
.lotto-result-between-item-box-smail-big .lotto-result-between-item span img{ width: 16px;}
*/

.cir-red {
  background: transparent;
  border: 5px solid #960000;
}
.cir-blue {
  background: transparent;
  border: 5px solid #003F9A;
}
.l-result-icon {
  display: inline-block;
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 20px;
  height: 18px;
  background-color: #EADFCD !important;
  width: 18px;
}



.f-end-line{height: 3px; background: linear-gradient(180deg, #373f63 , #38404d 0%, #5d5f6e); margin-top: 6px;}
/*right*/
.content-right-col-box-blue{padding-top: 16px !important;padding-left: 16px !important;padding-right: 8px !important;}
.content-right-col-box-red{padding-top: 16px !important;padding-left: 8px !important;padding-right: 16px !important;}
.content-right-box-red{height: 156px;background: linear-gradient(180deg, #bb1614 0, #d66263 51%, #c52d28);border: 1px solid white;position: relative;border-radius: 3px;text-align: center;}
.content-right-box-blue{height: 156px;background: linear-gradient(180deg, #0f488b 0, #2c5e9d 51%, #124479);border: 1px solid white;position: relative;border-radius: 3px;text-align: center;}
.content-right-box-blue ul{list-style: none;padding-left: 0 !important ;}
.content-right-box-red ul{list-style: none;padding-left: 0 !important ;}
.content-right-box-blue ul li:first-child{color: white;font-size: 2.16rem;line-height: 96px; font-family: nsk-bold;}
.content-right-box-red ul li:first-child{color: white;font-size: 2.16rem;line-height: 96px; font-family: nsk-bold;}
.content-right-box-blue ul li:last-child{color: white;font-size: 1.16rem;line-height: 10px;}
.content-right-box-red ul li:last-child{color: white;font-size: 1.16rem;line-height: 10px;}
.content-right-box-red .content-box{height: 26px;width: 100%;background-color: white;position: absolute;bottom: 0;color: #b41006;text-align: center;}
.content-right-box-blue .content-box{height: 26px;width: 100%;background-color: white;position: absolute;bottom: 0;color: #0c478b;text-align: center;}
.content-right-item{padding-top: 16px !important;padding-left: 16px !important;padding-right: 16px !important;display: grid;grid-template-columns: auto auto auto auto auto ; gap: 0px;}
.content-right-item .right-item-box{border: 1px solid white;float: left;margin-right: 6px;border-radius: 3px;}
.content-right-item .right-item-box:last-child{ margin-right: 0px;}
.content-right-item .right-item-box .item-box-head{height: 32px;text-align: center;background: linear-gradient(180deg, #75b9fa 0, #b6dcff 51%, #71a9fc);font-weight: 700;color: #131ac0;font-size: 1.1rem;line-height: 30px;}
.right-item-box{background-color: #0859aa;}
.content-right-item .right-item-box ul{list-style: none;padding-left: 0 !important;padding-top: 12px;padding-bottom: 6px;}
.content-right-item .right-item-box ul li{font-size: .75rem;color: white;text-align: center;}
.content-right-textbox{padding-top: 16px !important;padding-left: 16px !important;padding-right: 16px !important;}
.content-right-textbox ul{list-style: none;padding-left: 0 !important;}
.content-right-textbox ul li:first-child{color: white;} 
.content-right-textbox ul li .item-right{float: right;}
.content-right-textbox .text-yellow{color: yellow;}
.content-right-textbox .text-bg{font-size: 1rem;}
.content-right-textbox .text-sm{font-size: .85rem;}
.content-right-textbox ul .input-bet{padding-top: 6px;position: relative;}
.content-right-textbox ul .input-bet input{border: 1px solid white;padding: 8px 62px 8px 8px;width: 100%;color: white !important;}

.content-right-textbox ul .input-bet span{border: 1px solid white;width: 62px;height: 42px;position: absolute;right: 0; text-align: center; line-height: 30px}
.content-right-textbox ul .input-bet span button{color: yellow;font-size: 1.85rem; }
.content-right-textbox ul .input-bet span:hover{cursor: pointer;}
.content-right-buttom{padding-top: 16px !important;padding-left: 16px !important;padding-right: 16px !important;display: grid;grid-template-columns: auto auto auto auto ;gap: 0px;}
.content-right-buttom > .lotto-button-item{ border: 1px solid white; float: left; margin-right: 11px; margin-bottom: 12px; border-radius: 18px 18px 18px 18px; background: linear-gradient(180deg, #0146fb , #46aeff 0%, #0146fb, #0146fb); }
.content-right-buttom > .lotto-button-item:nth-child(4){ margin-right: 0px; }
.content-right-buttom > .lotto-button-item:nth-child(8){margin-right: 0px; }
.lotto-button-item div{width: 98%;height: 98%;border: 1px solid rgb(54, 44, 44);  border-radius: 18px 18px 18px 18px;margin: auto; padding: auto;text-align: center;font-size: 1.2rem;color: white; line-height: 36px; font-family: nsk-bold !important;}
.lotto-button-item:hover{cursor: pointer;}

.content-right-box-blue:hover{cursor: pointer;}
.content-right-box-red:hover{cursor: pointer;}
.right-item-box:hover{cursor: pointer;}

.blink {
  animation: blinking 1s steps(5, start) infinite;
  -webkit-animation: blinking 1s steps(5, start) infinite;
  animation: blinking 1s infinite;
}


@keyframes blinking {
  0% {
    box-shadow: 2px 2px 2px 2px transparent inset;
  }

  50% {
    /* background: #A14E4A;
    outline: 5px solid #90802F; */
    box-shadow: 0px 0px 0px 0px #A14E4A inset;
    background: pink;
  }

  100% {
    box-shadow: 2px 2px 2px 2px transparent inset;
  }
}
.l-number-active {
  background: #bc1e2d;
  color: #fff;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:-5px;
}
.number {
  float: left;
  text-align: center;
  height: 37px;
}
.focus-one-result {
  background-image: url("/images/red_btn.png");
  background-repeat: no-repeat;
  background-size: 45px 45px;
  background-position: center;
}
.l-logo-result-random-result {
  text-align: center;
  margin-top: 0px;
  line-height: 50px;
  color: #fff !important;
  font-weight: 700;
  background: linear-gradient(180deg, #fff700 0, #f70);
  color: #000;
  font-size: 27px;
  font-weight: 700;
  position: relative;
}

@media screen and (min-width: 1904px){
 /*left*/
  .list-result-head ul li{padding-left: 12px; padding-top: 12px;padding-bottom: 12px;font-size: 1.2rem;}
  .title-current{padding: 12px 12px 12px 12px;font-size: 1.2rem;}
  .list-result-body ul{padding-left: 10px;}
  .list-result-body ul li{padding-top: 6px;padding-bottom: 6px;font-size: .85rem;}
  .list-head li{color: #0c478b;font-size: .95rem !important;padding-top: 6px;padding-bottom: 6px;}
  /*right*/
  .content-right-box-red{height: 236px;}
  .content-right-box-blue{height: 236px;}
  .content-right-box-blue ul li:first-child{font-size: 2.56rem;line-height: 106px;}
  .content-right-box-red ul li:first-child{font-size: 2.56rem;line-height: 106px;}
  .content-right-box-blue ul li:last-child{font-size: 1.36rem;line-height: 20px;}
  .content-right-box-red ul li:last-child{font-size: 1.36rem;line-height: 20px;}
  .content-right-box-red .content-box{height: 36px; line-height: 40px;}
  .content-right-box-blue .content-box{height: 36px; line-height: 40px;}
  .content-right-item .right-item-box .item-box-head{height: 42px;font-size: 1.16rem;line-height: 40px;}
  .content-right-item .right-item-box ul{padding-top: 22px;padding-bottom: 12px; }
  .content-right-item .right-item-box ul li{font-size: .85rem; line-height: 20px;}
  .lotto-button-item div{font-size: 1.46rem;line-height: 50px;}
  /*
  .lotto-result-between-item-box .lotto-result-between-item span{top: 10%;left: 30%;}
  .lotto-result-between-item-box-smail-big .lotto-result-between-item span{ top: 10%;left: 30%;}
  */
 
  .lotto-result-between-min .tb-record tr td{width: 34.75px; height: 34.75px; min-width: 34.75px;}
  .lotto-result-between-min .tb-record tr td .range_1{ line-height: 20px; margin-left: 6px; width: 24px; height: 24px; font-size: .85rem; }
  .lotto-result-between-min .tb-record tr td .range_2{ line-height: 20px; margin-left: 6px; width: 24px; height: 24px; font-size: .85rem; }
  .lotto-result-between-min .tb-record tr td .range_3{ line-height: 20px; margin-left: 6px; width: 24px; height: 24px; font-size: .85rem; }
  .lotto-result-between-min .tb-record tr td .range_4{ line-height: 20px; margin-left: 6px; width: 24px; height: 24px; font-size: .85rem; }
  .lotto-result-between-min .tb-record tr td .range_5{ line-height: 20px; margin-left: 6px; width: 24px; height: 24px; font-size: .85rem; }

  .cir-red { border: 6px solid #960000;}
  .cir-blue {border: 6px solid #003F9A;}
  .l-result-icon {font-size: 20px; height: 22px;width: 22px;}

  /*.lotto-nav-head .head-right-last .span-number{ font-size: 1.85rem; right: 20px; bottom: 16px}*/
 
  .lotto-nav-head .head-right-right ul li{ padding-right: 40px;}
  .lotto-nav-head .head-right-right ul li .item-right{ margin-left: 16px;}
  .x-x-lotto-resul-x-1{padding-bottom: 10px !important;}
  .x-x-lotto-resul-x-2{padding-bottom: 10px !important;}
}

/*  1263 959*/
@media screen and (max-width: 1263px){
  /*left*/
  .list-result-head ul li{padding-left: 8px; padding-top: 8px;padding-bottom: 8px;font-size: .86rem;}
  .title-current{padding: 6px 6px 6px 6px;font-size: .90rem;}
  .list-result-body ul{padding-left: 6px;}
  .list-result-body ul li{padding-top: 3px;padding-bottom: 3px;font-size: .65rem;}
  .list-head li{color: #0c478b;font-size: .85rem !important;padding-top: 6px;padding-bottom: 6px;}
  .list-result-body ul li .row-item{ float: right;}
  .list-result-body ul li .row-item .col-item-3:first-child{padding-left: 10px; padding-right: 20px;}
  .list-result-body ul li .row-item .col-item-3{padding-left: 15px;}
  .list-result-body ul li .row-item .col-item-3:last-child{padding-right: 20px;}
  /*right*/
  .content-right-box-red{height: 136px;}
  .content-right-box-blue{height: 136px;}
  .content-right-box-blue ul li:first-child{font-size: 1.66rem;line-height: 56px;}
  .content-right-box-red ul li:first-child{font-size: 1.66rem;line-height: 56px;}
  .content-right-box-blue ul li:last-child{font-size: 1rem;line-height: 13px;}
  .content-right-box-red ul li:last-child{font-size: 1rem;line-height: 13px;}
  .content-right-box-red .content-box{height: 30px; line-height: 36px;}
  .content-right-box-blue .content-box{height: 30px; line-height: 36px;}
  .content-right-item .right-item-box .item-box-head{height: 32px;font-size: 1rem;line-height: 30px;}
  .content-right-item .right-item-box ul{padding-top: 13px;padding-bottom: 10px; }
  .content-right-item .right-item-box ul li{font-size: .55rem; line-height: 20px;}
  .lotto-button-item div{font-size: .78rem;line-height: 30px;}
  /*
  .lotto-result-between-item-box .lotto-result-between-item span{font-size: .65rem;top: 9%;left: 8%;width: 13px;height: 13px;}
  .lotto-result-between-item-box-smail-big .lotto-result-between-item span{font-size: .75rem;top: 9%;left: 10%;width: 13px;height: 13px;}
  .lotto-result-between-item-box-smail-big .lotto-result-between-item span img{ width: 13px;}
  */
  .lotto-result-between-min .tb-record tr td{width: 17px; height: 17px;}
  .lotto-result-between-min .tb-record tr td .range_1{ line-height: 12px; margin-left: 1px; width: 14px; height: 14px; font-size: .55rem; }
  .lotto-result-between-min .tb-record tr td .range_2{ line-height: 12px; margin-left: 1px; width: 14px; height: 14px; font-size: .55rem; }
  .lotto-result-between-min .tb-record tr td .range_3{ line-height: 12px; margin-left: 1px; width: 14px; height: 14px; font-size: .55rem; }
  .lotto-result-between-min .tb-record tr td .range_4{ line-height: 12px; margin-left: 1px; width: 14px; height: 14px; font-size: .55rem; }
  .lotto-result-between-min .tb-record tr td .range_5{ line-height: 12px; margin-left: 1px; width: 14px; height: 14px; font-size: .55rem; }

  .cir-red { border: 4px solid #960000;}
  .cir-blue {border: 4px solid #003F9A;}
  .l-result-icon {font-size: 16px; height: 16px;width: 16px;}

  /*.lotto-nav-head .head-right-last .span-number{ font-size: 1.15rem; right: 8px; bottom: 70px}*/
  .content-right-textbox ul .input-bet input{padding: 8px 62px 8px 8px !important;}

  .lotto-nav-head .head-right-right ul li{ padding-right: 10px;}
  .lotto-nav-head .head-right-right ul li .item-right{ margin-left: 10px;}

  .content-right-textbox .text-bg{font-size: .75rem;}
  .content-right-textbox .text-sm{font-size: .60rem;}

   .x-x-lotto-resul-x-1{padding-bottom: 10px !important;}
  .x-x-lotto-resul-x-2{padding-bottom: 10px !important;}
}

@media screen and (max-width: 959px){
 /*left*/
  .list-result-head ul li{padding-left: 12px; padding-top: 12px;padding-bottom: 12px;font-size: 1.2rem;}
  .title-current{padding: 12px 12px 12px 12px;font-size: 1.2rem;}
  .list-result-body ul{padding-left: 10px;}
  .list-result-body ul li{padding-top: 6px;padding-bottom: 6px;font-size: .85rem;}
  .list-head li{color: #0c478b;font-size: .95rem !important;padding-top: 6px;padding-bottom: 6px;}
  /*right*/
  .content-right-box-red{height: 296px;}
  .content-right-box-blue{height: 296px;}
  .content-right-box-blue ul li:first-child{font-size: 3.86rem;line-height: 146px;}
  .content-right-box-red ul li:first-child{font-size: 3.86rem;line-height: 146px;}
  .content-right-box-blue ul li:last-child{font-size: 1.96rem;line-height: 20px;}
  .content-right-box-red ul li:last-child{font-size: 1.96rem;line-height: 20px;}
  .content-right-box-red .content-box{height: 46px; line-height: 50px; font-size: 1.50rem;}
  .content-right-box-blue .content-box{height: 46px; line-height: 50px; font-size: 1.50rem;}
  .content-right-item .right-item-box .item-box-head{height: 62px;font-size: 1.96rem;line-height: 60px;}
  .content-right-item .right-item-box ul{padding-top: 22px;padding-bottom: 12px; }
  .content-right-item .right-item-box ul li{font-size: 1rem; line-height: 36px;}
  .lotto-button-item div{font-size: 1.56rem;line-height: 60px;}
  .grid-container > div {font-size: 1.12rem;padding-top: 12px;padding-bottom: 12px;}
  /*
  .lotto-result-between-item-box > .lotto-result-between-item {font-size: 1rem;padding-top: 12px;padding-bottom: 15px;}
  .lotto-result-between-item-box .lotto-result-between-item span{font-size: .75rem;top: 15%;left: 22%;width: 18px;height: 18px;}
  .lotto-result-between-item-box-smail-big > .lotto-result-between-item{font-size: 1rem;padding-top: 12px;padding-bottom: 15px;}
  .lotto-result-between-item-box-smail-big .lotto-result-between-item span{font-size: .75rem;top: 15%;left: 22%;width: 20px;height: 20px;}
  .lotto-result-between-item-box-smail-big .lotto-result-between-item span img{ width: 18px;}
  */

  .lotto-result-between-min .tb-record tr td{width: 34.75px; height: 34.75px;}
  .lotto-result-between-min .tb-record tr td .range_1{ line-height: 20px; margin-left: 6px; width: 24px; height: 24px; font-size: .85rem; }
  .lotto-result-between-min .tb-record tr td .range_2{ line-height: 20px; margin-left: 6px; width: 24px; height: 24px; font-size: .85rem; }
  .lotto-result-between-min .tb-record tr td .range_3{ line-height: 20px; margin-left: 6px; width: 24px; height: 24px; font-size: .85rem; }
  .lotto-result-between-min .tb-record tr td .range_4{ line-height: 20px; margin-left: 6px; width: 24px; height: 24px; font-size: .85rem; }
  .lotto-result-between-min .tb-record tr td .range_5{ line-height: 20px; margin-left: 6px; width: 24px; height: 24px; font-size: .85rem; }
 

  .cir-red { border: 6px solid #960000;}
  .cir-blue {border: 6px solid #003F9A;}
  .l-result-icon {font-size: 20px; height: 22px;width: 22px;}

  /*.lotto-nav-head .head-right-last .span-number{ font-size: 1.65rem; right: 46px; bottom: 20px}*/
  .content-right-textbox ul .input-bet input{padding: 8px 62px 8px 8px !important;}

  .lotto-nav-head .head-right-right ul li{ padding-right: 40px;}
  .lotto-nav-head .head-right-right ul li .item-right{ margin-left: 16px;}
  .lotto-number{ line-height: 10px;}
  .l-number-active{margin-top:-12px !important;}
  .my-order-right{order: 2;}
  .my-order-middle{order: 1; height: auto; margin-bottom: 6px;}
  .my-order-left{order: 3;}

  .content-right-on-mobi{display: block;}
  .content-middle-on-mobi{display: none;}
}

/* screen iphone  iPhone 6+ */
@media screen and (max-width: 600px){
 /*left*/
  .list-result-head ul li{padding-left: 12px; padding-top: 12px;padding-bottom: 12px;font-size: 1.2rem;}
  .title-current{padding: 12px 12px 12px 12px;font-size: 1.2rem;}
  .list-result-body ul{padding-left: 10px;}
  .list-result-body ul li{padding-top: 6px;padding-bottom: 6px;font-size: .85rem;}
  .list-head li{color: #0c478b;font-size: .95rem !important;padding-top: 6px;padding-bottom: 6px;}
  /*right*/
  .content-right-box-red{height: 200px;}
  .content-right-box-blue{height: 200px;}
  .content-right-box-blue ul li:first-child{font-size: 2.86rem;line-height: 100px;}
  .content-right-box-red ul li:first-child{font-size: 2.86rem;line-height: 100px;}
  .content-right-box-blue ul li:last-child{font-size: 1.2rem;line-height: 10px;}
  .content-right-box-red ul li:last-child{font-size: 1.2rem;line-height: 10px;}
  .content-right-box-red .content-box{height: 36px; line-height: 40px; font-size: 1rem;}
  .content-right-box-blue .content-box{height: 36px; line-height: 40px; font-size: 1rem;}
  .content-right-item .right-item-box .item-box-head{height: 42px;font-size: 1.2rem;line-height: 40px;}
  .content-right-item .right-item-box ul{padding-top: 12px;padding-bottom: 8px; }
  .content-right-item .right-item-box ul li{font-size: .85rem; line-height: 36px;}
  .lotto-button-item div{font-size: 1rem;line-height: 40px;}
  .grid-container > div {font-size: 1rem;padding-top: 12px;padding-bottom: 12px;}
  /*
  .lotto-result-between-item-box > .lotto-result-between-item {font-size: 1rem;padding-top: 6px;padding-bottom: 15px;}
  .lotto-result-between-item-box .lotto-result-between-item span{font-size: .65rem;top: 10%;left: 10%;width: 14px;height: 14px;}
  .lotto-result-between-item-box-smail-big > .lotto-result-between-item{font-size: 1rem;padding-top: 6px;padding-bottom: 15px;}
  .lotto-result-between-item-box-smail-big .lotto-result-between-item span{font-size: .75rem;top: 10%;left: 12%;width: 14px;height: 14px;}
  .lotto-result-between-item-box-smail-big .lotto-result-between-item span img{ width: 14px;}
  */

  .lotto-result-between-min .tb-record tr td{width: 20px; height: 20px;}
  .lotto-result-between-min .tb-record tr td .range_1{ line-height: 14px; margin-left: 3px; width: 16px; height: 16px; font-size: .55rem; }
  .lotto-result-between-min .tb-record tr td .range_2{ line-height: 14px; margin-left: 3px; width: 16px; height: 16px; font-size: .55rem; }
  .lotto-result-between-min .tb-record tr td .range_3{ line-height: 14px; margin-left: 3px; width: 16px; height: 16px; font-size: .55rem; }
  .lotto-result-between-min .tb-record tr td .range_4{ line-height: 14px; margin-left: 3px; width: 16px; height: 16px; font-size: .55rem; }
  .lotto-result-between-min .tb-record tr td .range_5{ line-height: 14px; margin-left: 3px; width: 16px; height: 16px; font-size: .55rem; }


  .cir-red { border: 4px solid #960000;}
  .cir-blue {border: 4px solid #003F9A;}
  .l-result-icon {font-size: 16px; height: 16px;width: 16px;}
  /*.lotto-nav-head .head-right-last .span-number{ font-size: 1.15rem; right: 12px; bottom: 20px}*/
  
  .content-right-textbox ul .input-bet input{padding: 8px 62px 8px 8px !important;}

  .lotto-nav-head .head-right-right ul li{ padding-right: 20px;}
  .lotto-nav-head .head-right-right ul li .item-right{ margin-left: 16px;}
  .lotto-number{ line-height: 10px;}
  .l-number-active{margin-top:-12px !important;}

  .my-order-right{order: 2;}
  .my-order-middle{order: 1;}
  .my-order-left{order: 3;}
  .content-right-on-mobi{display: block;}
  .content-middle-on-mobi{display: none;}
}

@media screen and (max-width: 450px){
 .lotto-result-between-box{min-height: 144px;}
}

/* screen iphone  12/13 pro max */
@media screen and (max-width: 428px){
 
  .lotto-nav-head .head-right-right ul li{ padding-right: 10px;}
  .lotto-nav-head .head-right-right ul li .item-right{ margin-left: 10px;}
  .lotto-number{ line-height: 10px;}
  .l-number-active{margin-top:-12px !important;}

  .my-order-right{order: 2;}
  .my-order-middle{order: 1;}
  .my-order-left{order: 3;}

  .lotto-result-between-min .tb-record tr td{width: 18px; height: 15px;}
  .lotto-result-between-min .tb-record tr td .range_1{ line-height: 16px; margin-left: 1px; width: 14px; height: 14px; font-size: .70rem;}
  .lotto-result-between-min .tb-record tr td .range_2{ line-height: 16px; margin-left: 1px; width: 14px; height: 14px; font-size: .70rem; }
  .lotto-result-between-min .tb-record tr td .range_3{ line-height: 16px; margin-left: 1px; width: 14px; height: 14px; font-size: .70rem; }
  .lotto-result-between-min .tb-record tr td .range_4{ line-height: 16px; margin-left: 1px; width: 14px; height: 14px; font-size: .70rem; }
  .lotto-result-between-min .tb-record tr td .range_5{ line-height: 16px; margin-left: 1px; width: 14px; height: 14px; font-size: .70rem; }
  .content-right-on-mobi{display: block;}
  .content-middle-on-mobi{display: none;}
}

@media screen and (max-width: 414px){
 
 .lotto-nav-head .head-right-right ul li{ padding-right: 6px;}
 .lotto-nav-head .head-right-right ul li .item-right{ margin-left: 6px;}
 .lotto-number{ line-height: 10px;}
 .l-number-active{margin-top:-12px !important;}

 .my-order-right{order: 2;}
 .my-order-middle{order: 1;}
 .my-order-left{order: 3;}

 .lotto-result-between-min .tb-record tr td{width: 18px; height: 15px;}
 .lotto-result-between-min .tb-record tr td .range_1{ line-height: 16px; margin-left: 1px; width: 14px; height: 14px; font-size: .70rem;}
 .lotto-result-between-min .tb-record tr td .range_2{ line-height: 16px; margin-left: 1px; width: 14px; height: 14px; font-size: .70rem; }
 .lotto-result-between-min .tb-record tr td .range_3{ line-height: 16px; margin-left: 1px; width: 14px; height: 14px; font-size: .70rem; }
 .lotto-result-between-min .tb-record tr td .range_4{ line-height: 16px; margin-left: 1px; width: 14px; height: 14px; font-size: .70rem; }
 .lotto-result-between-min .tb-record tr td .range_5{ line-height: 16px; margin-left: 1px; width: 14px; height: 14px; font-size: .70rem; }

 .content-right-on-mobi{display: block;}
 .content-middle-on-mobi{display: none;}

 .lotto-result-between-box{min-height: 144px;}
}

/* screen iphone  11 */
@media screen and (max-width: 375px){
 
 .lotto-nav-head .head-right-right ul li{ padding-right: 20px;}
 .lotto-nav-head .head-right-right ul li .item-right{ margin-left: 16px;}
 .lotto-number{ line-height: 10px;}
 .l-number-active{margin-top:-12px !important;}

 .my-order-right{order: 2;}
 .my-order-middle{order: 1;}
 .my-order-left{order: 3;}

 .lotto-result-between-min .tb-record tr td{width: 17px; height: 15px;}
 .lotto-result-between-min .tb-record tr td .range_1{ line-height: 11px; margin-left: 2px; width: 11px; height: 11px; font-size: .65rem;}
 .lotto-result-between-min .tb-record tr td .range_2{ line-height: 11px; margin-left: 2px; width: 11px; height: 11px; font-size: .65rem; }
 .lotto-result-between-min .tb-record tr td .range_3{ line-height: 11px; margin-left: 2px; width: 11px; height: 11px; font-size: .65rem; }
 .lotto-result-between-min .tb-record tr td .range_4{ line-height: 11px; margin-left: 2px; width: 11px; height: 11px; font-size: .65rem; }
 .lotto-result-between-min .tb-record tr td .range_5{ line-height: 11px; margin-left: 2px; width: 11px; height: 11px; font-size: .65rem; }

 .lotto-nav-head .head-right-right{width: 60% !important;}
.lotto-nav-head .head-right-right ul li{ font-size: .70rem;  padding-right: 2px;}
.lotto-nav-head .head-right-right ul li .item-right{ margin-left: 2px;}

.content-right-on-mobi{display: block;}
.content-middle-on-mobi{display: none;}
 
}


</style>
<style scoped>
.container {
  max-width: 1360px!important;
}
</style>
