<template>
    <v-flex class="form-login-section">
        <div id="wrapper">
            <div class="auth-page login-wrapper">
                <div class="login-panel position-relative">
                    <v-form ref="form" v-model="validate" lazy-validation @submit.prevent="login"
                        class="form-container">
                        <div class="form-group fg-container pt-2">
                            <div class="btn-login-title">
                                <h5 style="color:black">
                                    {{ $t("title.SignIn") }}
                                </h5>

                            </div>
                        </div>
                        <div class="form-group section-input">
                            <div class="" style="position: relative;">
                                <p style="color:black" class="pt-3 pb-0 mb-0">Username</p>
                                <input v-model="passUser.user_name" placeholder="Enter your username" :rules="[rules.required]"
                                    required type="text" id="username" class="form-control " />
                            </div>
                            <div class="eye-toggable" style="position: relative;">
                                <p style="color:black" class="pt-3 pb-0 mb-0">Password</p>
                                <input v-model="passUser.password" :rules="[rules.required, rules.min]"
                                    placeholder="Enter your password" :type="!isShowText ? 'password' : 'text'" required
                                    autocomplete="on" class="form-control form-control-user">
                                <div class="eye" @click="isShowText = !isShowText">
                                    <svg class="svg-inline--fa fa-eye fa-w-16" data-prefix="fas" data-icon="eye"
                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                                        data-fa-i2svg="">
                                        <path fill="currentColor"
                                            d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                            <div class="mt-2 mb-5" style="position: relative;">
                                <v-row>
                                    <input placeholder="User Name" type="checkbox" id="username" class="mt-3 mx-3" />
                                    <p style="color:black" class="pt-3 pb-0 mb-0">Remember password</p>
                                </v-row>
                            </div>
                        </div>
                        <div class=" submit-btn" ><button v-if="!passLoading" type="submit"
                                class="btn btn-secondary btn-user btn-block mb-1 fg-container">
                                {{ $t('title.log') }}
                            </button> <button v-if="passLoading" type="button" disabled="disabled" id="login-submitting"
                                class="btn btn-secondary btn-user btn-block fg-container">
                                {{ $t('title.logging') }}
                            </button></div>
                    </v-form>
                </div>
            </div>
        </div>
    </v-flex>

</template> 
  
<script>
import "@/assets/css/login.css";
export default {
    props: ['passUser', 'passValid', 'passLoading', 'passLanguage'],
    data() {
        return {
            rules: {
                required: [(value) => !!value || 'Required.'],
                min: v => (v && 0 !== v.length >= 6) || 'Min 6 characters',
                emailMatch: () => (`The email and password you entered don't match`),
            },
            validate: false,
            formLogo: '/images/logo.png',
            isShowText: false
        }
    },
    methods: {
        reset() {
            this.$refs.form.reset();
        },
        async login() {
            if (this.$refs.form.validate()) {
                this.$emit("login", this.passUser);
            } else {
                this.reset();
            }
        },
        _changeLanguage(language) {
            this.$emit("changeLanguage", language);
        }
    }
}
</script>