<template>
    <div class="plus-m-box-row-col" style="overflow:hidden">
        <div class="plus-m-box-col">
            <!-- @click="handleOk" -->
          <div class="plus-m-box-col-head"  >
            <span>A</span>
          </div>
          <div class="plus-m-box-body">
            <div class="plus-m-box-body-content plus-m-color-yellow " :class="passClientRandom && passClientRandom[0] ?'animated heartBeat' : ''">
              <span class="">{{passClientRandom && passClientRandom[0] ? passClientRandom[0] : passRandomNumber? passRandomNumber: '??'}}</span>
            </div>
          </div>
        </div>
    
        <div class="plus-m-box-col">
          <div class="plus-m-box-col-head">
            <span>B</span>
          </div>
          <div class="plus-m-box-body">
            <div class="plus-m-box-body-content plus-m-color-blue" :class="passClientRandom && passClientRandom[1] ?'animated heartBeat' : ''">
              <span>{{passClientRandom && passClientRandom[1] ? passClientRandom[1] : passClientRandom[0] && passRandomNumber ? passRandomNumber : '??'}}</span>
            </div>
          </div>
        </div>
    
        <div class="plus-m-box-col">
          <div class="plus-m-box-col-head">
            <span>C</span>
          </div>
          <div class="plus-m-box-body">
            <div class="plus-m-box-body-content plus-m-color-green" :class="passClientRandom && passClientRandom[2] ?'animated heartBeat' : ''">
              <span>{{passClientRandom && passClientRandom[2] ? passClientRandom[2] : passClientRandom[1] && passRandomNumber ? passRandomNumber : '??'}}</span>
            </div>
          </div>
        </div>
    
        <div class="plus-m-box-col">
          <div class="plus-m-box-col-head">
            <span>D</span>
          </div>
          <div class="plus-m-box-body">
            <div class="plus-m-box-body-content plus-m-color-maroon" :class="passClientRandom && passClientRandom[3] ?'animated heartBeat' : ''">
              <span>{{passClientRandom && passClientRandom[3] ? passClientRandom[3] :  passClientRandom[2] && passRandomNumber ? passRandomNumber : '??'}}</span>
            </div>
          </div>
        </div>
    
        <div class="plus-m-box-col">
          <div class="plus-m-box-col-head">
            <span>E</span>
          </div>
          <div class="plus-m-box-body">
            <div class="plus-m-box-body-content plus-m-color-red" :class="passClientRandom && passClientRandom[4] ?'animated heartBeat' : ''">
              <span>{{passClientRandom && passClientRandom[4] ? passClientRandom[4] :  passClientRandom[3] && passRandomNumber ? passRandomNumber : '??'}}</span>
            </div>
          </div>
        </div>
    
      </div>
</template>
<script>
import "@/assets/css/lottery.css";
export default({
  props: ["passRandomNumber","passClientRandom"],

})
</script>
<style>
</style>