<template>
    <v-flex class="login-logo-section">
        <div class="m-auto text-center" style="position:relative">
            <img class="hero-img mb-5" src="/images/Cockfight.png">
        </div>
    </v-flex>
</template> 
<script>
import "@/assets/css/login.css";
export default {

}
</script>