<template>
  <div>
    <v-app>
      <div fluid fill-height class="pa-0 background">
        <div class="header-login" style="z-index:1000"></div>
              <a class="desktop-screen" href="/" style="z-index:1100; margin-top:2px; position: absolute;   transform: translate(-142%);;">
                <img src="../../assets/images/h_homepage-logo.png" width="290" height="63" style="object-fit:cover">
                <!-- <v-select :items="items" label="Outlined style" solo></v-select> -->
              </a>
        <v-layout class="form-section-container desktop-screen">
          <logo-section />
          <form-section :passValid="valid" :passUser="user" :passLoading="loading" :passLanguage="lang"
            v-on:login="handleLogin(user)" v-on:changeLanguage="handleChangeLanguage" />
        </v-layout>
        <v-layout class="mobile-screen">
          <form-section-mobile :passValid="valid" :passUser="user" :passLoading="loading" :passLanguage="lang"
            v-on:login="handleLogin(user)" v-on:changeLanguage="handleChangeLanguage" />
        </v-layout>
      </div>
    </v-app>
  </div>
</template> 

<script>
import "@/assets/css/login.css";
import { mapActions, mapMutations } from "vuex";
import LogoSection from './components/LogoSection.vue';
import FormSection from './components/FormSection.vue';
import FormSectionMobile from './components/FormSectionMobile.vue';
export default {
  components: { LogoSection, FormSection, FormSectionMobile },

  data() {
    return {
      user: {
        user_name: null,
        password: null,
        app_id: null
      },
      select: ['English'],
      showPassword: false,
      password: 'Password',
      loading: false,
      error: false,
      rules: {
        required: [(value) => !!value || 'Required.'],
        min: v => (v && 0 !== v.length >= 6) || 'Min 6 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
      valid: false,
      url: "https://www.dropbox.com/s/wc1ixvksogfnq5n/SBC-Mobile_prodRelease.apk?dl=1",
      lang: ''
    }
  },
  computed: {
  },
  mounted() {
    this.lang = this.$cookies.get("local");
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    
    _findChannelType(url){
            switch(url){
                case 'LOTTO' : return 90;
                case 'YUKI' : return 91;
                case 'LOTTERY' : return 92;
            }
        },
    async handleLogin() {
      try {
        this.loading = true;
        this.user.app_id = process.env.VUE_APP_ID
        const response = await this.fetchLogIn(this.user);
        if (response.data.code) {
          const { token, user } = response.data.data;
          if (user.is_suspend) {
            this.$toastr.e(`Your account have been blooked!`)
          } else {
            this.$cookies.set("sbc_token", "Bearer " + token, 0);
                            this.$cookies.set('sbc_channel_type', user.channel_list ? user.channel_list[0] : '');
                            this.$cookies.set('sbc_home_channel', user.home_channel ? user.home_channel[0] : '');
                            this.$cookies.set('sbc_live_channel', user.live_channel ? user.live_channel[0] : '');
                            this.$cookies.set('sbc_slot_channel', user.slot_channel ? this._findChannelType(user.slot_channel[0]) : '');
                            this.$cookies.set("sbc_user", user);
                            this.UPDATE_CHANNEL_ID(user.channel_list[0])
                            this.UPDATE_CHANNEL_LIST(user.channel_list)
                            this.$router.push({ path: "/home" });
            // this.myInfo();
          }
        } else {
          this.$toastr.e(`${response.data.message.descriptions}`)
          this.error = true;
        }
      } catch (error) {
        this.$toastr.e(`${error}`)
        this.error = true;
        return error;
      } finally {
        this.loading = false;
      }

    },
    async myInfo() {
      try {
        this.loading = true;
        const response = await this.fetchMyInfo();
        if (response.data.code) {
          const { user_name, balance, currency_type } = response.data.data;
          this.$cookies.set("user_name", user_name);
          this.$cookies.set("balance", balance);
          this.$cookies.set("currency_type", currency_type);
        }
        else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        return error;
      } finally {
        this.loading = false;
      }
    },
    handleChangeLanguage(language) {
      this.lang = language;
      switch (language) {
        case 'english':
          this.changeLangEn();
          break;
        case 'khmer':
          this.changeLangKh();
          break;
        case 'thai':
          this.changeLangTh();
          break;
      }
    },
    changeLangEn() {
      let lang = this.$cookies.get("local");

      this.$i18n.locale = lang && lang == "english" ? "english" : "english";
      this.$cookies.set("local", this.$i18n.locale);

    },
    changeLangKh() {
      let lang = this.$cookies.get("local");
      this.$i18n.locale = lang && lang == "khmer" ? "khmer" : "khmer";
      this.$cookies.set("local", this.$i18n.locale);

    },
    changeLangTh() {
      let lang = this.$cookies.get("local");
      this.$i18n.locale = lang && lang == "thai" ? "thai" : "thai";
      this.$cookies.set("local", this.$i18n.locale);

    },
    changeLangVN() {
      let lang = this.$cookies.get("local");
      this.$i18n.locale = lang && lang == "vietnam" ? "vietnam" : "vietnam";
      this.$cookies.set("local", this.$i18n.locale);

    },
    ...mapActions("$_auth", ["fetchLogIn"]),
    ...mapActions("$_myinfo", ["fetchMyInfo"]),
    ...mapMutations("$_modules", [
      "UPDATE_CHANNEL_ID",
      "UPDATE_CHANNEL_LIST",
    ]),
  }
}
</script>
<style>
.container.fill-height {
  max-width: 100vw !important;
  width: 100vw !important;
}
</style>