<template>
  <v-app id="inspire" style="max-width:100vw!important">
    <v-layout style="max-width:100vw!important"> 
      <GlobalComponentDesktop :passDrawer="drawer" :passUserInfo="userInfor" v-on:invokeLogout="onLogout">
      </GlobalComponentDesktop>
      <v-main class="background-style">
        <v-container fluid class="ma-auto pa-0">
          <router-view></router-view>
        </v-container>
      </v-main>
      <Footer :passDrawer="drawer" :passUserInfo="userInfor" v-on:invokeLogout="onLogout"></Footer>
    </v-layout>
  </v-app>
</template>


<script>
import "@/assets/css/header.css";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import GlobalComponentDesktop from '../components/GlobalComponentDesktop.vue';
import Footer from '../components/GlobalComponentFooter.vue';
export default {
  components: { GlobalComponentDesktop, Footer },
  data: () => ({
    channelType: 1,
    channelList: null,
    inset: true,
    drawer: null,
    error: false,
    user_name: null,
    dialog: false,
    balance: null,
    data: {
      account_info: null,
      descending: true,
      sortBy: '_id',
      fields: ['fight_no', 'date']
    },
  }),
  created() {
    this.getUserInfo();
  },
  watch: {
    channelId: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.channelType = newVal
      }
    },
  },
  computed: {
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },
  mounted() {
    this.channelType = this.$cookies.get('sbc_channel_type') ? this.$cookies.get('sbc_channel_type') : 1
    this.channelList = this.$cookies.get("sbc_user").channel_list
    this.isSuspend()
  },

  methods: {
    isSuspend() {
      if (this.userInfor) {
        if (this.userInfor.is_suspend) {
          this.onLogout()
        }
      }
    },
    async setChannelType(channelId) {
      if (this.channelId != channelId) {
        this.UPDATE_CHANNEL_ID(channelId);
        this.channelType = channelId
        this.$cookies.set("sbc_channel_type", channelId);
      }
    },
    onLogout() {

      this.$socket.removeAllListeners()
      this.$socket.disconnect()
      this.$cookies.remove('sbc_channel_type')
      this.$cookies.remove('sbc_token')
      this.$router.push("/login")
    },
    currencyFormat(num) {
      if (num) {
        num = parseFloat(num)
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }
      return num
    },
    getUserCurrency() {
      if (this.userInfor.currency_type === 1) {
        return "$"
      } else if (this.userInfor.currency_type === 2) {
        return "៛"
      } else if (this.userInfor.currency_type === 3) {
        return "฿"
      }
    },
    async getUserInfo() {
      await this.fetchUserInfo();
    },
    ...mapActions("$_myinfo", [
      "fetchMyInfo",
    ]),
    ...mapActions("$_modules", ["updateBalance", "updateUserInfo", "fetchUserInfo"]),
    ...mapMutations("$_modules", [
      "UPDATE_USER_INFO",
      "UPDATE_CHANNEL_ID",
      "UPDATE_AUDIO"]),
  },
}   
</script>
<style scoped>
  @media (min-width: 599) {
    .desktop-screen-mobile {
      display: none !important;
      background: red;
      width: 600px;
      height: 300px;
    }
  }

  @media (max-width: 600) {
    .mobile-screen-home {
      display: none !important;
      background: red;
      width: 600px;
      height: 300px;
    }
  }


  @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

</style>