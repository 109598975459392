<template>
  <div>
    <div class="col-sm-12 col-12">
      <h4 style="color: rgb(255, 255, 255); text-align: center">
        {{$t("title.Rwithdraw")}}
      </h4>
    </div>

    <div
      class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12"
      style="
        border-radius: 25px;
        background-color:#080c1a;
        margin-top: 5px;
      "
    >
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12">
          <div style="margin-bottom: 8px; text-align: center">
            <h5>{{$t("title.cash")}}</h5>
          </div>
          <div style="text-align: center">
            <span
              class="v-chip theme--light v-size--default"
              style="
                background-color: #15244e;
                border-color: rgb(255, 255, 255);
                color: rgb(255, 255, 255);
                caret-color: rgb(255, 255, 255);
              "
            >
              <span class="v-chip__content">
                <b
                  >{{ currencyFormat(this.dataReportWithdraw.total_amount) }}
                  {{ getUserCurrency() }}</b
                >
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>

    <v-col cols="12" style="position: relative">
      <v-row>
        <v-col lg="12" sm="6">
          <v-btn-toggle
            v-model="toggle_exclusive"
            mandatory
            class="btn-group group-menu-scr"
          >
          <v-col lg="1" md="3" sm="4" class="mx-0 px-1">
              <v-btn
                @click="getData('all')"
                class="btn-fansy"
                style="width: 100% !important"
              >
                <!-- <v-icon>mdi-clipboard-file-outline</v-icon> -->
                <span style="font-family: 'khmer mef1'">{{
                  $t("button.all")
                }}</span>
              </v-btn>
            </v-col>
            <v-col lg="1" md="3" sm="4" class="mx-0 px-1">
              <v-btn
                @click="getData('today')"
                class="btn-fansy"
                style="width: 100% !important"
              >
                <!-- <v-icon>mdi-clipboard-file-outline</v-icon> -->
                <span style="font-family: 'khmer mef1'">{{
                  $t("button.today")
                }}</span>
              </v-btn>
            </v-col>
            <v-col lg="2" md="3" sm="4" class="mx-0 px-1">
              <v-btn
                @click="getData('last_day')"
                class="btn-fansy"
                style="width: 100% !important"
              >
                <!-- <v-icon>mdi-file-account</v-icon> -->
                <span style="font-family: 'khmer mef1'">{{
                  $t("button.last_day")
                }}</span>
              </v-btn>
            </v-col>
            <v-col lg="2" md="3" sm="4" class="mx-0 px-1">
              <v-btn
                @click="getData('this_week')"
                class="btn-fansy"
                style="width: 100% !important"
              >
                <!-- <v-icon>mdi-file-clock</v-icon> -->
                <span style="font-family: 'khmer mef1'">{{
                  $t("button.this_week")
                }}</span>
              </v-btn>
            </v-col>
            <v-col lg="2" md="3" sm="4" class="mx-0 px-1">
              <v-btn
                @click="getData('last_week')"
                class="btn-fansy"
                style="width: 100% !important"
              >
                <!-- <v-icon>mdi-file-edit</v-icon> -->
                <span style="font-family: 'khmer mef1'">{{
                  $t("button.last_week")
                }}</span>
              </v-btn>
            </v-col>
            <v-col lg="2" md="3" sm="4" class="mx-0 px-1">
              <v-btn
                @click="getData('this_month')"
                class="btn-fansy"
                style="width: 100% !important"
              >
                <span style="font-family: 'khmer mef1'">{{
                  $t("button.this_month")
                }}</span>
              </v-btn>
            </v-col>
            <v-col lg="2" md="3" sm="4" class="mx-0 px-1">
              <v-btn
                @click="getData('last_month')"
                class="btn-fansy"
                style="width: 100% !important"
              >
                <!-- <v-icon>mdi-file-code-outline</v-icon> -->
                <span style="font-family: 'khmer mef1'">{{
                  $t("button.last_month")
                }}</span>
              </v-btn>
            </v-col>
          </v-btn-toggle>
        </v-col>

        <v-col cols="12" sm="12" class="sm-more-top">
          <v-row>
            <v-col cols="1" sm="1" md="1" lg="1"></v-col>
            <v-col cols="4" sm="4" md="4" lg="4" class="xs-more-space-left">
              <v-menu
                v-model="v_startDate"
                :close-on-content-click="false"
                :nudge-right="100"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    :label="$t('title.start_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="custom-label-color"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="v_startDate = false"
                  class="custom-label-color"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4" sm="4" md="4" lg="4" class="xs-more-space-right">
              <v-menu
                v-model="v_endDate"
                :close-on-content-click="false"
                :nudge-right="100"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    :label="$t('title.end_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="custom-label-color"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @input="v_endDate = false"
                  class="custom-label-color"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="2"
              sm="2"
              md="2"
              lg="2"
              class="more-right xs-more-space-submit"
            >
              <v-btn
                class="white--text btn-custom mt-4"
                depressed
                block
                @click="getData('search')"
                style="
                  font-family: 'khmer mef1';
                  background-color: #003366;
                  color: #ffffff;
                  height: 32px !important;
                "
              >
                {{ $t("button.search") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <!-- <div class="xs-more-top">
    <div v-if="this.dataReportWithdraw.total_amount >= 0" role="alert" class="v-alert v-sheet theme--light v-alert--border v-alert--dense v-alert--text v-alert--border-left green--text">
      <div class="v-alert__wrapper">
        <div class="v-alert__content"> Total Amount: <b>{{ currencyFormat(this.dataReportWithdraw.total_amount) }} {{getUserCurrency()}}</b> </div>
        <div class="v-alert__border v-alert__border--left"></div>
      </div>
    </div>
    <div v-if="this.dataReportWithdraw.total_amount < 0" role="alert" class="v-alert v-sheet theme--light v-alert--border v-alert--dense v-alert--text v-alert--border-left red--text">
      <div class="v-alert__wrapper">
        <div class="v-alert__content"> Total Amount: <b>{{ currencyFormat(this.dataReportWithdraw.total_amount) }} {{getUserCurrency()}}</b> </div>
        <div class="v-alert__border v-alert__border--left"></div>
      </div>
    </div>
  </div> -->
  </div>
</template>

<script>
import headersReportWithdraw from "../_api/columnsReportWithdraw";
import { mapActions, mapGetters, mapMutations } from "vuex";
import * as moment from "moment";
export default {
  props: ["darkmode", "status", "params"],
  data() {
    return {
      headersReportWithdraw: headersReportWithdraw,
      data: {
        startDate: null,
        endDate: null,
      },
      startDate: null,
      endDate: null,
      v_startDate: null,
      v_endDate: null,
    };
  },

  computed: {
    ...mapGetters("$_withdraw", {
      dataReportWithdraw: "getReportWithdraw",
    }),
    ...mapGetters("$_withdraw", ["getLoading"]),
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
  },
  watch: {
    data: function () {
      // Emit this information to the parents component
      if (this.startDate != null && this.endDate != null) {
        this.$emit("child-checkbox", this.data);
      }
    },
  },
  methods: {
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    getUserCurrency() {
      if (this.userInfor.currency_type === 1) {
        return "$";
      } else if (this.userInfor.currency_type === 2) {
        return "៛";
      } else if (this.userInfor.currency_type === 3) {
        return "฿";
      }
    },
    async getUserInfo() {
      await this.fetchUserInfo();
    },
    async getData(type) {
      if (type === "all") {
        this.startDate = "";
        this.endDate = "";
        this.data.startDate = "";
        this.data.endDate = "";
        this.$emit("child-checkbox", this.data);
      }
      if (type === "today") {
        let _today = moment(new Date()).format("YYYY-MM-D");
        this.startDate = _today;
        this.endDate = _today;
        this.data.startDate = _today;
        this.data.endDate = _today;
        this.$emit("child-checkbox", this.data);
      } else if (type === "last_day") {
        let last_day = moment().subtract(1, "days").format("YYYY-MM-D");
        this.startDate = last_day;
        this.endDate = last_day;
        this.data.startDate = last_day;
        this.data.endDate = last_day;
        this.$emit("child-checkbox", this.data);
      } else if (type === "this_week") {
        let startOfWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");
        let endOfWeek = moment().endOf("isoWeek").format("YYYY-MM-DD");
        this.startDate = startOfWeek;
        this.endDate = endOfWeek;
        this.data.startDate = startOfWeek;
        this.data.endDate = endOfWeek;
        this.$emit("child-checkbox", this.data);
      } else if (type === "last_week") {
        let startOfLastWeek = moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
          .format("YYYY-MM-DD");
        let endOfLastWeek = moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
          .format("YYYY-MM-DD");
        this.startDate = startOfLastWeek;
        this.endDate = endOfLastWeek;
        this.data.startDate = startOfLastWeek;
        this.data.endDate = endOfLastWeek;
        this.$emit("child-checkbox", this.data);
      } else if (type === "this_month") {
        let startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
        let endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
        this.startDate = startOfMonth;
        this.endDate = endOfMonth;
        this.data.startDate = startOfMonth;
        this.data.endDate = endOfMonth;
        this.$emit("child-checkbox", this.data);
      } else if (type === "last_month") {
        let startOfLastMonth = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        let endOfLastMonth = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        this.startDate = startOfLastMonth;
        this.endDate = endOfLastMonth;
        this.data.startDate = startOfLastMonth;
        this.data.endDate = endOfLastMonth;
        this.$emit("child-checkbox", this.data);
      } else if (type === "search") {
        this.data.startDate = this.startDate;
        this.data.endDate = this.endDate;
        if (this.startDate != null && this.endDate != null) {
          this.$emit("child-checkbox", this.data);
        }
      }
    },
  },

  ...mapMutations("$_withdraw", ["REPORT_WITHDRAW"]),
  ...mapActions("$_withdraw", ["fetchReportWithdraw"]),
};
</script>

<style scope>
.btn-fansy {
  background: rgba(0, 0, 0, 0.12) !important;
  border: 2px #c55180 !important;
  border-radius: 5px !important;
  height: 35px !important;
  font-size: 0.7rem !important;
  color: white !important;
  font-size: 1rem;
  margin: 0px 5px 0px 0px;
  width: 14% !important;
  /* border-right:1px solid #172B58!important; */
}

.btn-fansy-all {
  background: rgba(0, 0, 0, 0.12) !important;
  border: 2px solid #c55180 !important;
  border-radius: 5px !important;
  font-size: 0.7rem !important;
  height: 35px !important;
  color: white !important;
  margin: 0px 5px 0px 0px;
  width: 13.36% !important;
}

.btn-group {
  width: 100%;
  margin: auto !important;
  background-color: transparent !important;
}

::-webkit-scrollbar {
  height: 5px;
}

.v-input__slot {
  padding: 0px;
}

.v-input__append-outer .v-icon,
.v-input__prepend-outer .v-icon {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: white !important;
}

.v-application--is-ltr .v-text-field .v-label {
  left: 12px !important;
}

/* Track */
html,
body {
  overflow: scroll !important;
  height: 100vh;
}

.custom-placeholer-color input::placeholder {
  color: white !important;
  opacity: 1;
}

.custom-label-color .v-label {
  color: white;
  opacity: 1;
}

.custom-placeholer-color input,
.custom-label-color input {
  color: white !important;
}

.v-label {
  color: white !important;
}

@media (min-width: 300px) and (max-width: 729px) {
  .sm-more-top {
    margin-top: -50px;
    margin-bottom: 210px;
  }

  .group-menu-scr {
    width: 80vw !important;
    margin: auto !important;
    background-color: transparent !important;
    max-width: 80vw;
    overflow-x: scroll !important;
  }

  .xs-more-top {
    margin-top: 25px;
  }

  .more-sm-top {
    height: 120px;
    position: absolute;
  }

  .sm-position {
    padding-top: 0px;
  }

  .xs-more-space-right {
    width: 80vw !important;
    max-width: 80vw !important;
    flex: 0 0 100%;
    position: absolute;
    left: 0px;
    top: 90px;
  }

  .xs-more-space-left {
    width: 80vw !important;
    max-width: 80vw !important;
    flex: 0 0 100%;
    position: absolute;
    left: 0px;
    top: 28px;
  }

  .xs-more-space-submit {
    position: absolute;
    top: 150px;
  }
}

@media (min-width: 730px) and (max-width: 959px) {
  .more-sm-top {
    height: 120px;
    position: absolute;
  }

  .sm-position {
    margin: top 20px, important !important;
    padding-top: 20px;
  }
}

@media (min-width: 960px) and (max-width: 1263px) {
  .sm-more-top {
    margin-top: 30px;
  }

  .more-sm-top {
    margin-top: 0px !important;
  }

  .sm-position {
    position: absolute;
    right: -37%;
    top: -78px;
  }

  .more-right {
    margin-right: 7% !important;
  }
}

@media (min-width: 1264px) and (max-width: 1433px) {
  .more-right {
    margin-right: 8px !important;
  }
}

@media (min-width: 1434) {
  .more-right {
    margin-right: 0px !important;
  }

  .more-sm-top {
    margin-top: 50px !important;
  }
}
</style>
