var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"w-100",style:(_vm.passChannel.is_open != true &&
      _vm.passSelectedChannel != 1 &&
      _vm.passSelectedChannel != 2 &&
      _vm.passSelectedChannel != 3 &&
      _vm.passSelectedChannel != 4 &&
      _vm.passSelectedChannel != 10 &&
      _vm.passSelectedChannel != 15 &&
      _vm.passSelectedChannel != 16 &&
      _vm.passSelectedChannel != 18 &&
      _vm.passSelectedChannel != 19 &&
      _vm.passSelectedChannel != 20 &&
      _vm.passSelectedChannel != 21 
        ? 'height:50px;'
        : _vm.passSelectedChannel == 10
        ? 'height:88px!important;'
        : '')},[((_vm.passSelectedChannel == 5) &&
    _vm.passChannel.is_open != true)?_c('ResultVideoKlaKlok',{attrs:{"passListKlaklouk":_vm.listKlaklouk}}):_vm._e(),(
        (_vm.passSelectedChannel == 6 || _vm.passSelectedChannel == 7) &&
        _vm.passChannel.is_open != true
      )?_c('ResultVideoBaccarat',{attrs:{"passPlayerCard1":_vm.playerCard1,"passPlayerCard2":_vm.playerCard2,"passPlayerCard3":_vm.playerCard3,"passBankerCard1":_vm.bankerCard1,"passBankerCard2":_vm.bankerCard2,"passBankerCard3":_vm.bankerCard3,"passPlayerPoint":_vm.playerPoint,"passBankerPoint":_vm.bankerPoint}}):_vm._e(),(
        (_vm.passSelectedChannel == 8 || _vm.passSelectedChannel == 9) &&
        _vm.passChannel.is_open != true
      )?_c('ResultVideoDragon',{attrs:{"passDragonCard":_vm.dragonCard,"passTigerCard":_vm.tigerCard}}):_vm._e(),(_vm.passSelectedChannel == 10 && _vm.passChannel.is_open != true)?_c('ResultVideoLotto',{attrs:{"passListLotto":_vm.listLotto}}):_vm._e(),(_vm.passSelectedChannel == 11 && _vm.passChannel.is_open != true)?_c('ResultVideoYuki',{attrs:{"passListYuki":_vm.listYuki}}):_vm._e(),(
        (_vm.passSelectedChannel == 12 || _vm.passSelectedChannel == 17) &&
        _vm.passChannel.is_open != true
      )?_c('ResultVideoApong',{attrs:{"passListAbong":_vm.listAbong}}):_vm._e(),(_vm.passSelectedChannel == 13 && _vm.passChannel.is_open != true)?_c('ResultVideoFantan',{attrs:{"passlistFantan":_vm.listFantan}}):_vm._e(),(_vm.passSelectedChannel == 14 && _vm.passChannel.is_open != true)?_c('ResultVideoTaisiv',{attrs:{"passListTaisiv":_vm.listTaiSiv}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }