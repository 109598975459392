import Vue from 'vue'
import App from './App.vue'
import router from './services/router'
import Request from './services/axios.js';
import VueCookies from 'vue-cookies';
import VueHtmlToPaper from 'vue-html-to-paper';
import store from './services/store.js'
import vuetify from '@/plugins/vuetify'
import mixin from './mixin';
import VueToastr from 'vue-toastr';
import i18n from './services/i18n';
import {io} from "socket.io-client";

import '@/assets/css/tailwind.css';
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

const options = {
    name: '_blank',
    specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
    styles: ['css/print.css']
};

// set global component
Vue.mixin(mixin);
Vue.use(VueCookies);
Vue.use(VueHtmlToPaper, options);
Vue.use(VueToastr, {defaultTimeout: 1100});

Vue.config.productionTip = false
Vue.prototype.$request = new Request();
//set global socket
Vue.prototype.$connectSocket = (token = Vue.$cookies.get('sbc_token'), channelType = Vue.$cookies.get('sbc_channel_type') ? Vue.$cookies.get('sbc_channel_type') : 1) => {
    if (token) {
        if(channelType == 1){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch1`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd1')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 2){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch2`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd2')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 3){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch3`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd3')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 4){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch4`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd4')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 5){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}klaklouk-ch5`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd5')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 6){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}baccarat-ch6`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd6')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 7){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}baccarat-ch7`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd7')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 8){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}dragon-ch8`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: dragonch8')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 9){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}dragon-ch9`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: dragon9')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 10){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}lotto-ch10`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd10')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 11){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}yuki-ch11`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd11')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 12){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}abong-ch12`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd12')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 13){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}fantan-ch13`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd13')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 14){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}taisiev-ch14`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd14')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 15){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch15`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd14')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 16){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch16`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd14')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 17){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}abong-ch17`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd14')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        
        if(channelType == 19){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}dragon-ch19`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: ch19')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 18){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}baccarat-ch18`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: ch18')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 20){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}han2-ch20`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: ch20')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 21){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}han3-ch21`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: ch21')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 90){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}slot-lotto`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd90')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 91){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}slot-yuki`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd91')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
        if(channelType == 92){
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}slot-pp78`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            });
            socket.on("connect", () => {
                console.log('socket: Connectd92')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
    }
};
new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
