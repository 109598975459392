<template>
  <!-- eslint-disable -->
  <div>
    <v-btn 
      @click="print(bettingReport._id)"
      x-small color="green"
      class="white--text"
      id="print-button"
    >
    Print
    </v-btn >
    <table :id="`printMe${bettingReport._id}`" hidden>
      <tbody>
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            aa2288
          </td>
        </tr>
        <br />
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            Bet ID: {{ bettingReport._id }}
          </td>
        </tr>
        <br />
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            -------------------------------------
          </td>
        </tr>
        <br />
        <tr>
          <td>
            <table>
              <tbody>
                <tr>
                  <td style="padding: 5px; text-align: left">Fight No:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ bettingReport.fight_no ? bettingReport.fight_no : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">Betting No:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ bettingReport.bet_name ? bettingReport.bet_name : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">Date:</td>
                  <td style="padding: 5px; text-align: left">
                    {{bettingReport.date}}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">Amount:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ bettingReport.amount ? bettingReport.amount : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">Payout:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ bettingReport.bet_payout ? bettingReport.bet_payout : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">Cast:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ bettingReport.cast ? bettingReport.cast : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">Amount Win:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ bettingReport.amount_win ? bettingReport.amount_win : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">Result Name:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ bettingReport.result_name ? bettingReport.result_name : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">Win/Lose</td>
                  <td style="padding: 5px; text-align: left">
                    <span>{{bettingReport.is_win ? "WIN" : "LOSE"}}</span>
                  </td>
                </tr> 
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            -------------------------------------
          </td>
        </tr>
        <br />
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: ["bettingReport"],
  methods: {
    async print(id) {
      // Pass the element id here
      await this.$htmlToPaper(`printMe${id}`);
    },
  },
};
</script>