<template>
    <div>
        <CockFightReportComponent
            v-if="passSelectedChannel == 18 || passSelectedChannel == 19 || passSelectedChannel == 1 || passSelectedChannel == 2 || passSelectedChannel == 3 || passSelectedChannel == 4 || passSelectedChannel == 15 || passSelectedChannel == 16"
            :passSelectedChannel="passSelectedChannel" :passLoadingTodayReport="passLoadingTodayReport" />

        <KlaklokReportComponent v-if="passSelectedChannel == 5"/>

        <BaccaratReportComponent v-if="passSelectedChannel == 6 || passSelectedChannel == 7" />

        <TigerDragonReportComponent v-if="passSelectedChannel == 8 || passSelectedChannel == 9" />

        <LottoReportComponent v-if="passSelectedChannel == 10"/>

        <YukiReportComponent v-if="passSelectedChannel == 11"/>

        <AbongReportComponent v-if="passSelectedChannel == 12 || passSelectedChannel == 17"/>

        <FantanReportComponent v-if="passSelectedChannel == 13"/>

        <TaiSievReportComponent v-if="passSelectedChannel == 14"/>

        <HanGameReportComponent v-if="passSelectedChannel == 20 || passSelectedChannel == 21"/>

    </div>
</template>

<script>

import CockFightReportComponent from "./_ReportBettingComponent/CockFightReportComponent.vue";
import BaccaratReportComponent from "./_ReportBettingComponent/BaccaratReportComponent.vue";
import TigerDragonReportComponent from "./_ReportBettingComponent/TigerDragonReportComponent.vue";
import KlaklokReportComponent from "./_ReportBettingComponent/KlaklokReportComponent.vue";
import LottoReportComponent from "./_ReportBettingComponent/LottoReportComponent.vue";
import YukiReportComponent from "./_ReportBettingComponent/YukiReportComponent.vue";
import AbongReportComponent from "./_ReportBettingComponent/AbongReportComponent.vue";
import FantanReportComponent from "./_ReportBettingComponent/FanTanReportComponent.vue";
import TaiSievReportComponent from "./_ReportBettingComponent/TaiSievReportComponent.vue";

import HanGameReportComponent from "./_ReportBettingComponent/HanGameReportComponent.vue";

export default {
    components: {
        CockFightReportComponent, BaccaratReportComponent, TigerDragonReportComponent, KlaklokReportComponent,
        LottoReportComponent, YukiReportComponent, AbongReportComponent, FantanReportComponent, TaiSievReportComponent,HanGameReportComponent
    },
    props: ['passLoadingTodayReport', 'passSelectedChannel'],
    data() {
        return {

        }
    },
}
</script>
<style>
/* width */
::-webkit-scrollbar {
    width: 5px
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(9, 17, 40, .7)
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: rgba(40, 53, 89, .8);
    border-radius: 10px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(78, 106, 181, .2)
}

@keyframes rotate {

    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }

}


.refresh-start {

    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: running;

}

.text-meron {
    color: #f93c3c !important
}

.txt-white {
    color: rgb(255, 255, 255, 0.5)
}

.txt-gold {
    color: #FFC008
}

.txt-grey {
    color: #6C757D
}
</style>