<template>
  <v-app v-cloak> 
    <router-view/>
  </v-app>
</template>

<script>
import "@/assets/css/font.css";
import "@/assets/css/base.css";
import "@/assets/css/vendors.css";
import { mapGetters } from "vuex";
import storeModules from "@/modules/_store";
import storeAuth from "@/modules/Auth/_store";
import storeDashboard from "@/modules/Dashboard/_store";
import storeDashboardLive from "@/modules/DashboardLive/_store";
import storeMyInfo from "@/modules/myInfo/_store";
import storeDeposit from "@/modules/deposit/_store";
import storeWithdraw from "@/modules/withdraw/_store";
import storeBetting from "@/modules/betting/_store";
import storeAllBetting from "@/modules/allstatement/_store";
import storeResult from "@/modules/result/_store";
import storeMachine from "@/modules/MachineGame/Lotto/_store";
import storeMachineYuki from "@/modules/MachineGame/yuki/_store";
import storeMachineLottery from "@/modules/MachineGame/Lottery/_store";
export default{
  name: "App",
  created(){
    this.registerStore();
  },
  computed: {
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
  },
  methods:{
    registerStore() {
        const MODULES = "$_modules";
        const AUTH = "$_auth";
        const DASHBOARD = "$_dashboard";
        const DASHBOARDLIVE = "$_dashboard_live";
        const MYINFO = "$_myinfo";
        const DEPOSIT = "$_deposit";
        const WITHDRAW = "$_withdraw";
        const BETTING = "$_betting";
        const ALLBETTING = "$_allstatement";
        const RESULT = "$_result";
        const MACHINE = "$_machine";
        const MACHINEYUKI = "$_machine_yuki";
        const MACHINE_LOTTERY = "$_machine_lottery";

        if (!(MACHINE in this.$store._modules.root._children)) {
          this.$store.registerModule(MACHINE, storeMachine);
        }
        if (!(MACHINEYUKI in this.$store._modules.root._children)) {
          this.$store.registerModule(MACHINEYUKI, storeMachineYuki);
        }
        if (!(MACHINE_LOTTERY in this.$store._modules.root._children)) {
          this.$store.registerModule(MACHINE_LOTTERY, storeMachineLottery);
        }
        if (!(MODULES in this.$store._modules.root._children)) {
          this.$store.registerModule(MODULES, storeModules);
        }
        if (!(AUTH in this.$store._modules.root._children)) {
          this.$store.registerModule(AUTH, storeAuth);
        }
        if (!(DASHBOARD in this.$store._modules.root._children)) {
          this.$store.registerModule(DASHBOARD, storeDashboard);
        }
        if (!(DASHBOARDLIVE in this.$store._modules.root._children)) {
          this.$store.registerModule(DASHBOARDLIVE, storeDashboardLive);
        }
        if (!(MYINFO in this.$store._modules.root._children)) {
          this.$store.registerModule(MYINFO, storeMyInfo);
        }        
        if (!(DEPOSIT in this.$store._modules.root._children)) {
          this.$store.registerModule(DEPOSIT, storeDeposit);
        }
        if (!(WITHDRAW in this.$store._modules.root._children)) {
          this.$store.registerModule(WITHDRAW, storeWithdraw);
        }
        if (!(BETTING in this.$store._modules.root._children)) {
          this.$store.registerModule(BETTING, storeBetting);
        }
        if (!(ALLBETTING in this.$store._modules.root._children)) {
          this.$store.registerModule(ALLBETTING, storeAllBetting);
        }
        if (!(RESULT in this.$store._modules.root._children)) {
          this.$store.registerModule(RESULT, storeResult);
        }
    },
  },
}
</script>
<style>
html {
  scroll-behavior: smooth;
}
@media (min-width: 1264px){

}
[v-cloak] > * { display:none }
 [v-cloak]::before { content: "Loading…" }
</style>

<!-- @media (min-width: 1904px){
  .container {
      max-width: 100vw!important;
   }
  } -->