export default {
    permission_list: 'Permission List',
    role_list: 'Role List',
    user_list: 'User List',
    menu_list: 'Menu List',
    create_permission: 'Create Permission',
    update_permission: 'Update Permission',
    create_role: 'Create Role',
    update_role: 'Update Role',
    create_user: 'Create User',
    update_user: 'Update User',
    create_menu: 'Create Menu',
    update_menu: 'Update Menu',
    change_password_user: 'Change Password User',
    welcome: 'ยินดีต้อนรับ:',
    account_balance: 'Account Balance',
    user_role: 'User Role',
    No:'เลขที่',
    your_balance:'ยอดเงินของคุณ',
    home:'บ้าน',
    sbc:'SBC',
    status:'สถานะ',
    time:'เวลา',
    result:'ผลลัพธ์',
    five_k:'1.5 $',
    ten_k:'2.5 $',
    twenty_k:'5 $',
    five_k1:'12.5 $',
    ten_100k:'25 $',
    twenty_200k:'50 $',
    five_500k:'125 $',
    one_1m:'1M $',
    payout:'การจ่ายเงิน',
    current_betting:'การพนัน',
    today_report:'รายงาน',
    fight:'ต่อสู้',
    type:'พิมพ์',
    amount:'จำนวน',
    action:'การกระทำ',
    my_profile:'ประวัติของฉัน',
    persional_info:'ข้อมูลส่วนบุคคล',
    change_password:'เปลี่ยนรหัสผ่าน',
    result_betting:'การเดิมพันผลลัพธ์',
    result_win:'ผลลัพธ์ชนะ',
    report:'รายงาน',
    MERON:'สีแดง',
    WALA:'สีแดง',
    tie:'เท่ากับ',
    cancel:'ยกเลิก',
    login:'เข้าสู่ระบบ',
    deposit:'เงินฝาก',
    withdraw:'ถอน',
    betting:'การพนัน',
    all_betting:'การพนัน(All)',
    channel1:'ช่อง 1',
    channel2:'ช่อง 2',
    channel3:'ช่อง 3',
    channel4:'ช่อง 4',
    betting1:'BET',
    min: 'เล็กที่สุด',
    max: 'ใหญ่ที่สุด',
    log:'เข้าสู่ระบบ',
    logging: 'กำลังลงชื่อเข้าใช้',
    changeLanguage :'เปลี่ยนภาษา',
    password: 'รหัสผ่าน',
    bet_between : 'พิสัย',
    bet_small_large: 'เล็ก / ใหญ่',
    // start from here
    gameIsClose:"การเดิมพันปิด",
    draw:'วาด',
    confirm:"ตกลง",
    Close:"ปิด",
    Open:"เปิด",
    Not_Open:"ไม่เปิด",
    fight_sign:"#",
    //sidebar
    user_center: "ข้อมูลผู้ใช้",
    all_report: "รายงานผลรวม",
    cash_ins: "รายงานการฝากเงิน",
    withdrawals: "รายงานการถอนเงิน",
    languages: "ภาษา",
    other: "อื่น",
    thin:"Under",
    thick:"Over",
    red:'Red',
    blue:'Blue',
    logout: "ทางออก",
    small:"เล็ก",
    large:"ใหญ่",
    return:"Return",
    Old_Balance:"ยอดเก่า",
    New_Balance:"ความสมดุลใหม่",
    Amount:'จำนวน',
    dateTime:'วันเวลา',
    cash:'เงินสด',
    Rdeposit:'รายงานการฝากเงิน',
    betLimit:'วงเงินเดิมพัน',
    pleaseBet:'กรุณาเดิมพัน',
    Rwithdraw:'ถอนรายงาน',
    start_date: 'วันที่เริ่มต้น',
    end_date: 'วันที่สิ้นสุด',
    date_time: 'วันเวลา',
    channel:'เกม',
    amount_win: 'จำนวนเงินที่ชนะ',
    total_win_lose: 'ชนะ/แพ้ทั้งหมด',
    total_amount: 'จำนวนเงินทั้งหมด',
    all_statement:'ข้อความทั้งหมด',
    no_data:"ไม่มีข้อมูล ...",
    odd_even:'คี่ / คู่',
    blue_red:'สีน้ำเงิน / สีแดง',
    result_year: "Result Year",
    result_small_big: "Result Small/Big",
    result_range: "Result Range",
    bet_total: 'ទឹកប្រាក់សរុប',
    total_cast:"นักแสดงทั้งหมด",
    total_amount_win:"การชำระคืนทั้งหมด",

    yuki_wipe : "หนู",
    yuki_street : "วัว",
    yuki_karl : "เสือ",
    yuki_thao : "กระต่าย",
    yuki_factory : "มังกร",
    yuki_net : "งู",
    yuki_thin : "บาง",
    yuki_thick : "หนา",
    yuki_mimi : "ม้า",
    yuki_mom : "แพะ",
    yuki_vk : "ลิง",
    yuki_roka : "ไก่",
    yuki_kor : "หมู",
    yuki_odd : "แปลก",
    yuki_pair : "คู่",
    yuki_red : "สีแดง",
    yuki_blue : "สีฟ้า",
    yuki_dog : "สุนัข",
    yuki_null : "null",


};
